import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Platform, IonAccordionGroup } from '@ionic/angular';
import { WebpageService } from '../../services/web.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { TextService } from '../../services/text.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss'],
  animations: [ ]
})
export class TopHeaderComponent implements OnInit, OnDestroy {

  @ViewChild('accordionGroup', { static: true }) accordionGroup!: IonAccordionGroup;
  rootCategories: any[] = [];
  searchbarInput: any = '';
  clearSearchText$: any;
  locale: any;
  isMobile = true;
  menuOpen = false;
  searchOpen = false;
  mobileMenuCategories:any;
  locale$: any;
  text:any = {};
  routes: any = {};
  homePath = '/';

  constructor(
    private platform: Platform,
    public textService: TextService,
    private router: Router,
    public webpageService: WebpageService) {


      if (this.platform.width() >= 600) {
        this.isMobile = false;
      }

      this.locale$ = this.webpageService.getLocale().subscribe(async locale => {
        if (locale !== null) {
          this.locale = locale;
          this.setHomePath();
          this.translateTexts();
          const deviceWidth = this.platform.width();
          if (deviceWidth >= 600) {
            this.rootCategories = await this.webpageService.getItem(`${locale}-topmenu`, `topmenu`);
          }
          if (deviceWidth < 600) {
            const menuData = await this.webpageService.getItem(`${locale}-mobilemenu`, `mobilemenu`);
            this.mobileMenuCategories = this.transformDataToMobileMenu(menuData);
          }
        }
      })
    }

  async ngOnInit() {
    this.clearSearchText$ = this.webpageService.clearSearchSubject.subscribe((clear:any) => {
      if (clear) {
        this.searchbarInput = '';
        this.webpageService.clearCompleted();
      }
    });
  }

  setHomePath() {
    if (this.locale !== 'en') { this.homePath = '/' + this.locale;  }
    else { this.homePath = '/'; }
  }

  openItem(category:any) {
    const navigationExtras: NavigationExtras = { state: { category }};
    this.router.navigate(['/' + category.path], navigationExtras);
  }

  goToAddImage() {
    if (this.isMobile) { this.collapseAccordionMenu(); }
    let route = this.textService.getRouteLocale('addItem', this.locale);
    this.router.navigate([`/${route}`]);
  }

  clearSearchbar() {
    //if (!this.isDesktop) {
    // npm install @capacitor/keyboard
    // npx cap sync
    // this.keyboard.hide();
   // }
  }

  searchItem(event: any) {
    this.searchbarInput = event.detail.value;
    this.webpageService.search(this.searchbarInput);
  }

  ngOnDestroy() { this.clearSearchText$.unsubscribe(); }


  /*transformMenuData(menuData: any)  {
    const categories:any = [];

    // Filter ROOT categories
    const rootCategories = menuData.filter((item:any) => item.categories === 'ROOT');

    // Map ROOT categories to our structure
    rootCategories.forEach((rootCategory:any) => {
      const category: any = {
        name: rootCategory.shortname,
        path: rootCategory.path,
        subcategories: []
      };

      // Find and add subcategories
      const subcategories = menuData.filter((item:any) => item.categories === category.name.toLowerCase());
      subcategories.forEach((subcategory:any) => {
        category.subcategories.push({ name: subcategory.shortname, path: subcategory.path });
      });

      categories.push(category);
    });

    return categories;
  }*/

  transformDataToMobileMenu(data: any)  {
    const roots = data.filter((category:any) => category.categories === 'ROOT');
    for (let root of roots) {
      let children = data.filter((category:any) => category.categories === root.slug);
      root.isExpandable = true;
      for (let child of children) {
        const grandchildren = data.filter((category:any) => category.categories === child.slug);
        child.children = grandchildren;
        child.isExpandable = false;
        if (grandchildren.length > 0) { child.isExpandable = true }
        for (let child of grandchildren) {

        }
      }
      root.children = children;
    }

    return roots;
  }


  goTo(event: MouseEvent, path: string) {
    event.stopPropagation(); // Stop event propagation
    this.collapseAccordionMenu();
    this.router.navigateByUrl(path); // Navigate to the specified path
    this.toggleMenu();
  }

  getColorForCategory(index:any) {
    if(index === 0) { return 'rgba(167, 199, 231, 0.2)'}
    if(index === 1) { return 'rgba(255, 127, 80, 0.2)'}
    if(index === 2) { return 'rgba(178, 240, 182, 0.2)'}
    if(index === 3) { return 'rgba(255, 192, 203, 0.2)'}
    else {
      return 'rgba(167, 199, 231, 0.2)';
    }
  }

  toggleMenu() { this.menuOpen = !this.menuOpen; }

  toggleSearch() { this.webpageService.showSearchBar = !this.webpageService.showSearchBar; }

  translateTexts() {
    this.text.websiteName = this.textService.getTextLocale('websiteName', 'topheader', this.locale);
    this.text.addImage = this.textService.getTextLocale('addImage', 'topheader', this.locale);
    this.text.placeholderText = this.textService.getTextLocale('placeholderText', 'topheader', this.locale);
    this.text.arialabel = this.textService.getTextLocale('arialabel', 'topheader', this.locale);
    this.text.ariaButtonSearch = this.textService.getTextLocale('ariaButtonSearch', 'topheader', this.locale);
    this.text.ariaButtonCloseSearch = this.textService.getTextLocale('ariaButtonCloseSearch', 'topheader', this.locale);
    this.text.ariaButtonCloseMenu = this.textService.getTextLocale('ariaButtonCloseMenu', 'topheader', this.locale);
    this.text.ariaButtonMenu = this.textService.getTextLocale('ariaButtonMenu', 'topheader', this.locale);
    this.text.privacyPolicy = this.textService.getTextLocale('privacyPolicy', 'footer', this.locale);
    this.text.contact = this.textService.getTextLocale('contact', 'footer', this.locale);
    this.routes.contact = this.textService.getRouteLocale('contact', this.locale);
    this.routes.privacyPolicy = this.textService.getRouteLocale('privacyPolicy', this.locale);
  }

  collapseAccordionMenu() {
    this.accordionGroup.value = undefined;
  }

}
