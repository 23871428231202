import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  constructor() { }

  getTextLocale(key:any, webpage:any, locale:any) {
    const text:any = {
      en: {
        addItem: {
          pageTitle: "Send us your photo!",
          messageError: "Error! Something went wrong!",
          messageSuccess: "You have successfully uplaoded your photo. Within 24h it will be published!",
          nameLabel: "Name",
          namePlaceholder: "Name...",
          uploadButtonText: "Upload",
          uploadSlogan: "Upload your image!"
        },
        contact: {
          pageTitle: "Contact Form",
          messageError: "Error! Something went wrong!",
          messageSuccess: "Thank you! Your message has been sent successfully. We will get back to you soon.",
          submit: "Submit",
          messageLabel: "Message",
          messagePlaceholder: "Your message...",
          nameLabel: "Name",
          namePlaceholder: "Name...",
          emailLabel: "Email",
          emailPlaceholder: "Enter email..."
        },
        footer: {
          label: "Footer navigation",
          contact: 'Contact',
          privacyPolicy: 'Privacy policy'
        },
        item: {
          adActionButton: 'Shop now',
        },
        privacyPolicy: {
          pageTitle: "Privacy Policy",
          contents: "Contents",
          introduction: "Welcome to goodtattoodesigns.com, your go-to source for tattoo inspiration and community sharing. We are committed to protecting the privacy of our visitors and contributors. This Privacy Policy outlines how we collect, use, share, and safeguard your personal information on our website. It also describes your data protection rights, including a right to object to some of the processing which goodtattoodesigns.com carries out. By using our website, you understand and agree to the terms of this Privacy Policy.",
          sections: [
            {
              subheadingRef: "subheadingRef1",
              subheading: "Information Collection and Use",
              section: "Our website collects information to provide better services to all our users. We collect information in the following ways: 1. Information you give us. For example, our website allows you to upload images, and when you do, we require you to provide a username or other information that will be publicly displayed with your image. 2. Information we get from your use of our services. We collect information about the services that you use and how you use them, like when you visit a page that shows our tattoo images or when you upload an image of your own. This information includes: - Device information - Log information - Location information - Unique application numbers - Local storage - Cookies and similar technologies. We use Google Analytics to collect, monitor, and analyze this type of information in order to increase our service's functionality. These third-party service providers have their own privacy policies addressing how they use such information."
            },
            {
              subheadingRef: "subheadingRef2",
              subheading: "Data Sharing and Disclosure",
              section: "We do not share personal information with companies, organizations, and individuals outside of goodtattoodesigns.com except in the following cases: - With your consent. We will share personal information with companies, organizations, or individuals outside of goodtattoodesigns.com when we have your consent to do so. - For external processing. We provide personal information to our affiliates or other trusted businesses or persons to process it for us, based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures. - For legal reasons. We will share personal information with companies, organizations, or individuals outside of goodtattoodesigns.com if we have a good faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to meet any applicable law, regulation, legal process, or enforceable governmental request."
            },
            {
              subheadingRef: "subheadingRef3",
              subheading: "Data Security",
              section: "We work hard to protect goodtattoodesigns.com and our users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold. In particular: - We encrypt many of our services using SSL. - We review our information collection, storage, and processing practices, including physical security measures, to guard against unauthorized access to systems. - We restrict access to personal information to goodtattoodesigns.com employees, contractors, and agents who need to know that information in order to process it for us, and who are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations."
            },
            {
              subheadingRef: "subheadingRef4",
              subheading: "Your Privacy Rights",
              section: "Depending on your location, you may have certain rights under data protection laws, including the right to access, correct, or delete your personal information or object to certain processing. If you wish to exercise any of these rights, please contact us at [contact information]. Note that we may need to verify your identity before processing your request."
            },
            {
              subheadingRef: "subheadingRef5",
              subheading: "Changes to Our Privacy Policy",
              section: "goodtattoodesigns.com may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page."
            }
          ]
        },
        privacyPolicyPopup: {
          slogan: "This website uses cookies to ensure you get the best experience on our website."
        },
        relatedArticles: {
          subheading: 'READ ALSO',
          ariaLabel: 'Related article: '
        },
        relatedCategories: {
          subheading: 'RELATED CATEGORIES',
          ariaLabel: 'Related category: '
        },
        relatedItems: {
          subheading: 'YOU MAY ALSO LIKE',
          ariaLabel: 'Related item: '
        },
        topheader: {
          websiteName: 'GOODTATTOODESIGNS',
          addImage: 'TATTOO IMAGE',
          placeholderText: 'Search...',
          arialabel: 'Go to homepage',
          ariaButtonSearch: 'Search button',
          ariaButtonCloseSearch: 'Close search button',
          ariaButtonCloseMenu: 'Close menu button',
          ariaButtonMenu: 'Menu button',
        }
      },
      de: {
        addItem: {
          pageTitle: "Schick uns dein Foto!",
          messageError: "Fehler! Etwas ist schiefgelaufen!",
          messageSuccess: "Du hast dein Foto erfolgreich hochgeladen. Innerhalb von 24h wird es veröffentlicht!",
          nameLabel: "Name",
          namePlaceholder: "Name...",
          uploadButtonText: "Hochladen",
          uploadSlogan: "Lade dein Bild hoch!"
        },
        contact: {
          messageError: "Fehler! Etwas ist schief gelaufen!",
          messageSuccess: "Danke! Ihre Nachricht wurde erfolgreich gesendet. Wir werden uns bald bei Ihnen melden.",
          pageTitle: "Kontaktformular",
          submit: "Absenden",
          messageLabel: "Nachricht",
          messagePlaceholder: "Ihre Nachricht...",
          nameLabel: "Name",
          namePlaceholder: "Name...",
          emailLabel: "E-Mail",
          emailPlaceholder: "E-Mail eingeben..."
        },
        footer: {
          label: "Fußzeilennavigation",
          contact: "Kontakt",
          privacyPolicy: "Datenschutzrichtlinie"
        },
        item: {
          adActionButton: 'Jetzt kaufen',
        },
        privacyPolicy: {
          pageTitle: "Datenschutzrichtlinie",
          contents: "Contents",
          introduction: "Willkommen auf unserer Webseite, die sich auf die Präsentation von Tattoobildern spezialisiert hat. Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. In dieser Datenschutzerklärung erläutern wir, wie wir Ihre Daten erfassen, verwenden und schützen.",
          sections: [
            {
              subheadingRef: "subheadingRef1",
              subheading: "Erfassung von Daten",
              section: "Wir verwenden Google Analytics, um Daten über das Verhalten unserer Besucher auf der Webseite zu sammeln. Diese Daten umfassen Informationen über die Nutzung der Webseite, wie z.B. Seitenaufrufe, Verweildauer und die Navigation auf der Webseite. Die Erfassung dieser Daten erfolgt anonym, sodass keine direkten Rückschlüsse auf Ihre Person gezogen werden können."
            },
            {
              subheadingRef: "subheadingRef2",
              subheading: "Nutzung der Daten",
              section: "Die durch Google Analytics erfassten Daten werden verwendet, um die Nutzung unserer Webseite zu analysieren und unser Angebot stetig zu verbessern. Die Daten helfen uns, die Bedürfnisse unserer Nutzer besser zu verstehen und die Nutzererfahrung auf unserer Webseite zu optimieren."
            },
            {
              subheadingRef: "subheadingRef3",
              subheading: "Weitergabe von Daten",
              section: "Ihre Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben, ausgenommen hiervon sind lediglich unsere Dienstleister, die uns bei der Analyse der Daten unterstützen (z.B. Google Analytics). Alle Dienstleister sind verpflichtet, die Daten vertraulich zu behandeln und ausschließlich zu dem Zweck zu verwenden, für den sie ihnen überlassen wurden."
            },
            {
              subheadingRef: "subheadingRef4",
              subheading: "Eigene Bilder hinzufügen",
              section: "Als Nutzer unserer Webseite haben Sie die Möglichkeit, eigene Bilder von Tattoos hochzuladen. Bitte beachten Sie, dass Sie durch das Hochladen von Bildern bestätigen, dass Sie über die notwendigen Rechte an den Bildern verfügen und uns das Recht einräumen, diese auf unserer Webseite zu präsentieren. Wir ermutigen Sie, keine persönlich identifizierbaren Informationen oder sensible Daten auf den Bildern darzustellen."
            },
            {
              subheadingRef: "subheadingRef5",
              subheading: "Ihre Rechte",
              section: "Sie haben das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit und Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten. Bitte kontaktieren Sie uns, wenn Sie von diesen Rechten Gebrauch machen möchten. Sie haben auch das Recht, sich bei einer Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt."
            },
            {
              subheadingRef: "subheadingRef6",
              subheading: "Kontaktinformationen",
              section: "Wenn Sie Fragen oder Bedenken bezüglich unserer Datenschutzpraktiken haben, zögern Sie nicht, uns zu kontaktieren. Unsere Kontaktinformationen finden Sie auf unserer Webseite."
            }
          ]
        },
        privacyPolicyPopup: {
          slogan: "Diese Website verwendet Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website machen."
        },
        relatedArticles: {
          subheading: 'LESEN SIE AUCH',
          ariaLabel: 'Verwandter Artikel: '
        },
        relatedCategories: {
          subheading: 'VERWANDTE KATEGORIEN',
          ariaLabel: 'Verwandte Kategorie: '
        },
        relatedItems: {
          subheading: 'DAS KÖNNTE IHNEN AUCH GEFALLEN',
          ariaLabel: 'Verwandtes Element: '
        },
        topheader: {
          websiteName: 'GOODTATTOODESIGNS',
          addImage: 'TATTOOBILD',
          placeholderText: 'Suchen...',
          arialabel: 'Zur Startseite gehen',
          ariaButtonSearch: 'Suchknopf',
          ariaButtonCloseSearch: 'Suche schließen Knopf',
          ariaButtonCloseMenu: 'Menü schließen Knopf',
          ariaButtonMenu: 'Menüknopf',
        }
      },
      es: {
        addItem: {
          pageTitle: "¡Envíanos tu foto!",
          messageError: "¡Error! ¡Algo salió mal!",
          messageSuccess: "Has subido tu foto con éxito. ¡Se publicará en 24 horas!",
          nameLabel: "Nombre",
          namePlaceholder: "Nombre...",
          uploadButtonText: "Subir",
          uploadSlogan: "¡Sube tu imagen!"
        },
        contact: {
          messageError: "¡Error! Algo salió mal!",
          messageSuccess: "¡Gracias! Tu mensaje ha sido enviado exitosamente. Nos pondremos en contacto contigo pronto.",
          pageTitle: "Formulario de contacto",
          submit: "Enviar",
          messageLabel: "Mensaje",
          messagePlaceholder: "Tu mensaje...",
          nameLabel: "Nombre",
          namePlaceholder: "Nombre...",
          emailLabel: "Correo electrónico",
          emailPlaceholder: "Ingresa el correo electrónico..."
        },
        footer: {
          label: "Navegación del pie de página",
          contact: "Contacto",
          privacyPolicy: "Política de privacidad"
        },
        item: {
          adActionButton: "Comprar ahora",
        },
        privacyPolicy: {
          pageTitle: "Política de privacidad",
          contents: "Contenido",
          introduction: "Bienvenido a nuestra Política de Privacidad. Su privacidad es de suma importancia para nosotros. En [Nombre de su Empresa], estamos comprometidos a proteger la privacidad y la seguridad de los datos personales de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, usamos, procesamos y compartimos la información de los usuarios de nuestro sitio web dedicado a imágenes de tatuajes. Esta política se aplica a todos los visitantes del sitio y a aquellos que agregan sus propias imágenes.",
          sections: [
            {
              subheadingRef: "subheadingRef1",
              subheading: "Información que recopilamos",
              section: "Recopilamos información principalmente a través de Google Analytics para entender mejor cómo nuestros usuarios interactúan con el sitio web. Esto incluye datos sobre visitas al sitio, páginas vistas, tiempo de permanencia en el sitio, y cómo navegan los usuarios entre nuestras páginas. No recopilamos información personal identificable a través de Google Analytics."
            },
            {
              subheadingRef: "subheadingRef2",
              subheading: "Uso de la información",
              section: "Utilizamos la información recopilada a través de Google Analytics para mejorar la experiencia del usuario en nuestro sitio web, entender mejor las necesidades y preferencias de nuestros usuarios, y optimizar el rendimiento y la usabilidad de nuestro sitio. La información recopilada nos ayuda a desarrollar, testear y mejorar las funciones y servicios que ofrecemos."
            },
            {
              subheadingRef: "subheadingRef3",
              subheading: "Compartir y divulgar información",
              section: "No compartimos ni divulgamos ninguna información personal identificable recopilada a través de Google Analytics con terceros, excepto como se requiere por ley."
            },
            {
              subheadingRef: "subheadingRef4",
              subheading: "Derechos de los usuarios",
              section: "Los usuarios tienen derecho a acceder, rectificar, borrar, oponerse y limitar el procesamiento de sus datos personales enviados a nuestro sitio web. Si desea ejercer alguno de estos derechos, por favor contáctenos a través de los medios proporcionados en nuestro sitio web."
            },
            {
              subheadingRef: "subheadingRef5",
              subheading: "Seguridad de la información",
              section: "Nos comprometemos a proteger la seguridad de su información. Hemos implementado medidas técnicas y organizativas adecuadas para proteger los datos personales contra la pérdida, mal uso, acceso no autorizado, divulgación, alteración o destrucción."
            },
            {
              subheadingRef: "subheadingRef6",
              subheading: "Uso de cookies",
              section: "Utilizamos cookies y tecnologías similares para recopilar datos sobre el tráfico y la interacción del usuario con el sitio. Esto nos ayuda a ofrecer una experiencia personalizada y a analizar el rendimiento de nuestro sitio. Los usuarios pueden controlar el uso de cookies a través de la configuración de su navegador."
            },
            {
              subheadingRef: "subheadingRef7",
              subheading: "Enlaces a otros sitios",
              section: "Nuestro sitio web puede contener enlaces a otros sitios web que no son operados por nosotros. Si hace clic en un enlace de terceros, será dirigido al sitio de ese tercero. Le aconsejamos que revise la Política de Privacidad de cada sitio que visite. No tenemos control ni asumimos responsabilidad por el contenido, políticas de privacidad o prácticas de sitios de terceros."
            },
            {
              subheadingRef: "subheadingRef8",
              subheading: "Cambios en la Política de Privacidad",
              section: "Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Le recomendamos revisar periódicamente esta página para cualquier cambio. Los cambios a esta Política de Privacidad son efectivos cuando se publican en esta página."
            },
            {
              subheadingRef: "subheadingRef9",
              subheading: "Contacto",
              section: "Si tiene preguntas o comentarios sobre nuestra Política de Privacidad, no dude en contactarnos a través de los medios especificados en nuestro sitio web."
            }
          ]
        },
        privacyPolicyPopup: {
          slogan: "Este sitio utiliza cookies para asegurar que obtengas la mejor experiencia en nuestro sitio web."
        },
        relatedArticles: {
          subheading: 'LEER TAMBIÉN',
          ariaLabel: 'Artículo relacionado: '
        },
        relatedCategories: {
          subheading: 'CATEGORÍAS RELACIONADAS',
          ariaLabel: 'Categoría relacionada: '
        },
        relatedItems: {
          subheading: 'TAMBIÉN PODRÍA GUSTARTE',
          ariaLabel: 'Elemento relacionado: '
        },
        topheader: {
          websiteName: 'GOODTATTOODESIGNS',
          addImage: "IMAGEN DE TATUAJE",
          placeholderText: 'Buscar...',
          arialabel: 'Ir a la página principal',
          ariaButtonSearch: 'Botón de búsqueda',
          ariaButtonCloseSearch: 'Botón cerrar búsqueda',
          ariaButtonCloseMenu: 'Botón cerrar menú',
          ariaButtonMenu: 'Botón del menú',
        }
      },
      fr: {
        addItem: {
          pageTitle: "Envoyez-nous votre photo !",
          messageError: "Erreur ! Quelque chose s'est mal passé !",
          messageSuccess: "Vous avez téléchargé votre photo avec succès. Elle sera publiée dans les 24 heures !",
          nameLabel: "Nom",
          namePlaceholder: "Nom...",
          uploadButtonText: "Télécharger",
          uploadSlogan: "Téléchargez votre image !"
        },
        contact: {
          messageError: "Erreur! Quelque chose s'est mal passé!",
          messageSuccess: "Merci! Votre message a été envoyé avec succès. Nous vous recontacterons bientôt.",
          pageTitle: "Formulaire de contact",
          submit: "Envoyer",
          messageLabel: "Message",
          messagePlaceholder: "Votre message...",
          nameLabel: "Nom",
          namePlaceholder: "Nom...",
          emailLabel: "E-mail",
          emailPlaceholder: "Entrez l'e-mail..."
        },
        footer: {
          label: "Navigation de pied de page",
          contact: "Contact",
          privacyPolicy: "Politique de confidentialité"
        },
        item: {
          adActionButton: "Achetez maintenant",
        },
        privacyPolicy: {
          pageTitle: "Politique de confidentialité",
          contents: "Sommaire",
          introduction: "Bienvenue sur notre site Web dédié aux images de tatouages. La protection de votre vie privée est cruciale pour nous. Cette politique de confidentialité vise à vous informer sur les types de données que nous collectons, comment elles sont utilisées, et comment vous pouvez gérer votre information.",
          sections: [
            {
              subheadingRef: "subheadingRef1",
              subheading: "Collecte de données par Google Analytics",
              section: "Nous utilisons Google Analytics pour recueillir des informations sur la manière dont les visiteurs utilisent notre site. Cela inclut des données telles que votre emplacement géographique, le type de dispositif utilisé pour accéder à notre site, les pages visitées, et la durée de la visite. Ces informations sont utilisées pour améliorer l'expérience utilisateur sur notre site. Google Analytics collecte les données de manière anonyme, sans identifier individuellement les visiteurs."
            },
            {
              subheadingRef: "subheadingRef2",
              subheading: "Ajout d'images par les utilisateurs",
              section: "Les utilisateurs ont la possibilité d'ajouter leurs propres images de tatouages sur notre site. En téléchargeant une image, vous accordez à notre site Web le droit non exclusif de l'utiliser, de la reproduire, et de la distribuer. Nous vous demandons de ne télécharger que des images dont vous possédez les droits ou pour lesquelles vous avez obtenu l'autorisation nécessaire de la part des détenteurs des droits."
            },
            {
              subheadingRef: "subheadingRef3",
              subheading: "Gestion de vos informations",
              section: "Vous avez le droit de demander l'accès, la correction, ou la suppression de vos données personnelles enregistrées sur notre site à tout moment. Pour ce faire, veuillez nous contacter via les informations fournies sur notre site. Nous nous engageons à répondre à votre demande dans les meilleurs délais."
            },
            {
              subheadingRef: "subheadingRef4",
              subheading: "Modifications de la politique de confidentialité",
              section: "Notre politique de confidentialité peut être mise à jour de temps à autre. Toutes les modifications seront publiées sur cette page. Nous vous encourageons à consulter régulièrement cette page pour rester informé de toute mise à jour ou changement."
            }
          ]
        },
        privacyPolicyPopup: {
          slogan: "Ce site utilise des cookies pour vous garantir la meilleure expérience sur notre site."
        },
        relatedArticles: {
          subheading: 'LIRE AUSSI',
          ariaLabel: 'Article lié: '
        },
        relatedCategories: {
          subheading: 'CATÉGORIES LIÉES',
          ariaLabel: 'Catégorie liée: '
        },
        relatedItems: {
          subheading: 'VOUS POURRIEZ AUSSI AIMER',
          ariaLabel: 'Article lié: '
        },
        topheader: {
          websiteName: 'GOODTATTOODESIGNS',
          addImage: "IMAGE DE TATOUAGE",
          placeholderText: 'Rechercher...',
          arialabel: 'Aller à la page d\'accueil',
          ariaButtonSearch: 'Bouton de recherche',
          ariaButtonCloseSearch: 'Bouton fermer la recherche',
          ariaButtonCloseMenu: 'Bouton fermer le menu',
          ariaButtonMenu: 'Bouton du menu',
        }
      },
      it: {
        addItem: {
          pageTitle: "Invia la tua foto!",
          messageError: "Errore! Qualcosa è andato storto!",
          messageSuccess: "Hai caricato con successo la tua foto. Sarà pubblicata entro 24 ore!",
          nameLabel: "Nome",
          namePlaceholder: "Nome...",
          uploadButtonText: "Carica",
          uploadSlogan: "Carica la tua immagine!"
        },
        contact: {
          messageError: "Errore! Qualcosa è andato storto!",
          messageSuccess: "Grazie! Il tuo messaggio è stato inviato con successo. Ti risponderemo presto.",
          pageTitle: "Modulo di contatto",
          submit: "Invia",
          messageLabel: "Messaggio",
          messagePlaceholder: "Il tuo messaggio...",
          nameLabel: "Nome",
          namePlaceholder: "Nome...",
          emailLabel: "Email",
          emailPlaceholder: "Inserisci l'email..."
        },
        footer: {
          label: "Navigazione footer",
          contact: "Contatto",
          privacyPolicy: "Politica sulla privacy"
        },
        item: {
          adActionButton: "Acquista ora",
        },
        privacyPolicy: {
          pageTitle: "Politica sulla privacy",
          contents: "Contents",
          introduction: "Benvenuti alla nostra Politica sulla Privacy. La vostra privacy è di massima importanza per noi. Questa politica spiega come raccogliamo, usiamo, proteggiamo e, in alcuni casi, condividiamo le vostre informazioni personali sul nostro sito web dedicato alle immagini di tatuaggi.",
          sections: [
            {
              subheadingRef: "subheadingRef1",
              subheading: "Raccolta dei Dati",
              section: "Utilizziamo Google Analytics per raccogliere informazioni standard di registro internet e dettagli sui comportamenti dei visitatori in modo completamente anonimo. Questo può includere informazioni come il tipo di dispositivo, il sistema operativo, il tipo di browser, la lingua, l'orario e la data della visita, e l'indirizzo IP. L'obiettivo è comprendere meglio come i visitatori utilizzano il nostro sito per poter migliorare l'esperienza utente."
            },
            {
              subheadingRef: "subheadingRef2",
              subheading: "Contributi degli Utenti",
              section: "Gli utenti hanno la possibilità di aggiungere le proprie immagini di tatuaggi al nostro sito web. Incoraggiamo tutti gli utenti a non includere informazioni personali identificabili nelle immagini che caricano. Tuttavia, se decidete di condividere immagini che contengono informazioni personali identificabili, queste saranno accessibili agli altri utenti del sito. Non siamo responsabili per la privacy o la sicurezza delle informazioni che scegliete di rendere pubbliche."
            },
            {
              subheadingRef: "subheadingRef3",
              subheading: "Uso delle Informazioni",
              section: "Utilizziamo le informazioni raccolte tramite Google Analytics per analizzare il traffico del nostro sito web, migliorare i contenuti e le funzionalità, e per comprenderne l'uso da parte dei visitatori. Questo ci aiuta a offrire un'esperienza più ricca e mirata."
            },
            {
              subheadingRef: "subheadingRef4",
              subheading: "Condivisione delle Informazioni",
              section: "Non condividiamo le vostre informazioni personali con terzi, eccetto come richiesto dalla legge. Le informazioni raccolte tramite Google Analytics sono conservate da Google secondo le sue politiche sulla privacy."
            },
            {
              subheadingRef: "subheadingRef5",
              subheading: "Diritti degli Utenti",
              section: "Avete il diritto di accedere, rettificare o cancellare le vostre informazioni personali in nostro possesso in qualsiasi momento. Se avete caricato immagini sul nostro sito, potete richiederne la rimozione contattandoci direttamente tramite i canali forniti sul sito."
            },
            {
              subheadingRef: "subheadingRef6",
              subheading: "Sicurezza delle Informazioni",
              section: "Ci impegniamo a proteggere le vostre informazioni personali. Adottiamo misure di sicurezza tecniche e organizzative per prevenire la perdita, l'uso improprio, l'accesso non autorizzato, la divulgazione, l'alterazione o la distruzione delle vostre informazioni personali."
            },
            {
              subheadingRef: "subheadingRef7",
              subheading: "Modifiche alla Politica sulla Privacy",
              section: "Questa politica sulla privacy può essere aggiornata di tanto in tanto. Vi incoraggiamo a rivedere regolarmente questa pagina per essere informati su eventuali modifiche."
            },
            {
              subheadingRef: "subheadingRef8",
              subheading: "Contattaci",
              section: "Se avete domande o preoccupazioni riguardo alla nostra politica sulla privacy, non esitate a contattarci tramite i canali forniti sul nostro sito web."
            }
          ]
        },
        privacyPolicyPopup: {
          slogan: "Questo sito utilizza i cookie per assicurarti la migliore esperienza sul nostro sito."
        },
        relatedArticles: {
          subheading: 'LEGGI ANCHE',
          ariaLabel: 'Elemento correlato: '
        },
        relatedCategories: {
          subheading: 'CATEGORIE CORRELATE',
          ariaLabel: 'Categoria correlata: '
        },
        relatedItems: {
          subheading: 'POTREBBE PIACERTI ANCHE',
          ariaLabel: 'Elemento correlato: '
        },
        topheader: {
          websiteName: 'GOODTATTOODESIGNS',
          addImage: "IMMAGINE TATUAGGIO",
          placeholderText: 'Cerca...',
          arialabel: 'Vai alla homepage',
          ariaButtonSearch: 'Pulsante di ricerca',
          ariaButtonCloseSearch: 'Pulsante chiudi ricerca',
          ariaButtonCloseMenu: 'Pulsante chiudi menu',
          ariaButtonMenu: 'Pulsante del menu',
        }
      },
      pl: {
        addItem: {
          pageTitle: "Wyślij nam swoje zdjęcie!",
          messageError: "Błąd! Coś poszło nie tak!",
          messageSuccess: "Pomyślnie przesłałeś swoje zdjęcie. Zostanie opublikowane w ciągu 24 godzin!",
          nameLabel: "Imię",
          namePlaceholder: "Imię...",
          uploadButtonText: "Prześlij",
          uploadSlogan: "Prześlij swoje zdjęcie!"
        },
        contact: {
          messageError: "Błąd! Coś poszło nie tak!",
          messageSuccess: "Dziękujemy! Twoja wiadomość została pomyślnie wysłana. Odezwiemy się wkrótce.",
          pageTitle: "Formularz kontaktowy",
          submit: "Wyślij",
          messageLabel: "Wiadomość",
          messagePlaceholder: "Twoja wiadomość...",
          nameLabel: "Imię",
          namePlaceholder: "Imię...",
          emailLabel: "Email",
          emailPlaceholder: "Wpisz email..."
        },
        footer: {
          label: "Nawigacja stopki",
          contact: "Kontakt",
          privacyPolicy: "Polityka prywatności"
        },
        item: {
          adActionButton: "Kup teraz",
        },
        privacyPolicy: {
          pageTitle: "Polityka prywatności",
          contents: "Spis treści",
          introduction: "Niniejsza polityka prywatności ma na celu poinformowanie użytkowników o metodach zbierania, przetwarzania i wykorzystywania danych osobowych na stronie internetowej prezentującej zdjęcia tatuaży. Dane są zbierane wyłącznie za pośrednictwem Google Analytics. Użytkownicy mają możliwość dodawania własnych zdjęć na stronę.",
          sections: [
            {
              subheadingRef: "subheadingRef1",
              subheading: "Zbieranie danych",
              section: "Używamy Google Analytics do zbierania danych o sposobie korzystania z naszej strony przez użytkowników. Obejmuje to informacje takie jak typ przeglądarki, czas spędzony na stronie, odwiedzane strony oraz inne dane statystyczne. Nie zbieramy jednak informacji pozwalających na bezpośrednią identyfikację użytkownika."
            },
            {
              subheadingRef: "subheadingRef2",
              subheading: "Dodawanie zdjęć przez użytkowników",
              section: "Użytkownicy mogą dodawać własne zdjęcia tatuaży na stronę. Przesyłając zdjęcia, użytkownik wyraża zgodę na ich publiczne udostępnienie. Zalecamy nie umieszczanie na zdjęciach informacji pozwalających na bezpośrednią identyfikację osoby."
            },
            {
              subheadingRef: "subheadingRef3",
              subheading: "Wykorzystanie danych",
              section: "Dane zbierane za pośrednictwem Google Analytics są wykorzystywane wyłącznie do celów statystycznych oraz ulepszania działania strony. Nie udostępniamy tych danych żadnym trzecim stronom ani nie wykorzystujemy ich w celach marketingowych."
            },
            {
              subheadingRef: "subheadingRef4",
              subheading: "Prawa użytkownika",
              section: "Każdy użytkownik ma prawo do dostępu do swoich danych, sprostowania ich, usunięcia lub ograniczenia przetwarzania. Aby skorzystać z tych praw, należy skontaktować się z administratorem strony."
            },
            {
              subheadingRef: "subheadingRef5",
              subheading: "Bezpieczeństwo danych",
              section: "Dołożymy wszelkich starań, aby zapewnić bezpieczeństwo danych osobowych naszych użytkowników. Stosujemy odpowiednie środki techniczne oraz organizacyjne mające na celu ochronę danych przed nieautoryzowanym dostępem, zmianą, ujawnieniem lub zniszczeniem."
            },
            {
              subheadingRef: "subheadingRef6",
              subheading: "Zmiany w polityce prywatności",
              section: "Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej polityce prywatności. O wszelkich zmianach będziemy informować na stronie internetowej. Zachęcamy użytkowników do regularnego sprawdzania polityki w celu zapoznania się z aktualnymi informacjami o sposobie ochrony danych osobowych."
            }
          ]
        },
        privacyPolicyPopup: {
          slogan: "Ta strona używa ciasteczek (cookies), aby zapewnić Ci najlepsze doświadczenia na naszej stronie."
        },
        relatedArticles: {
          subheading: 'CZYTAJ RÓWNIEŻ',
          ariaLabel: 'Powiązany element: '
        },
        relatedCategories: {
          subheading: 'POWIĄZANE KATEGORIE',
          ariaLabel: 'Powiązana kategoria: '
        },
        relatedItems: {
          subheading: 'MOŻE CI SIĘ RÓWNIEŻ SPODOBAĆ',
          ariaLabel: 'Powiązany element: '
        },
        topheader: {
          websiteName: 'GOODTATTOODESIGNS',
          addImage: "DODAJ OBRAZ",
          placeholderText: 'Szukaj...',
          arialabel: 'Przejdź do strony głównej',
          ariaButtonSearch: 'Przycisk wyszukiwania',
          ariaButtonCloseSearch: 'Przycisk zamknij wyszukiwanie',
          ariaButtonCloseMenu: 'Przycisk zamknij menu',
          ariaButtonMenu: 'Przycisk menu',
        }
      },

    }
    if (text[locale] && text[locale][webpage]) {
      return text[locale][webpage][key]
    }
  }

  getRouteLocale(key:any, locale:any) {

    const routes:any = {
      en: {
        addItem: 'en/add-tattoo',
        contact: 'en/contact-us',
        privacyPolicy: 'en/privacy-policy'
      },
      de: {
        addItem: 'de/tattoo-hinzufuegen',
        contact: 'de/kontakt',
        privacyPolicy: 'de/datenschutzrichtlinie'
      },
      es: {
        addItem: 'es/agregar-tatuaje',
        contact: 'es/contacto',
        privacyPolicy: 'es/politica-de-privacidad'
      },
      fr: {
        addItem: 'fr/ajouter-tatouage',
        contact: 'fr/contact',
        privacyPolicy: 'fr/politique-de-confidentialite'
      },
      it: {
        addItem: 'it/aggiungi-tatuaggio',
        contact: 'it/contatto',
        privacyPolicy: 'it/politica-sulla-privacy'
      },
      pl: {
        addItem: 'pl/dodaj-tatuaz',
        contact: 'pl/kontakt-z-nami',
        privacyPolicy: 'pl/polityka-prywatnosci'
      }
    }
    return '/' + routes[locale][key];
  }


}