import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { WebpageService } from '../../services/web.service';
import { TextService } from '../../services/text.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { Platform } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.page.html',
  styleUrls: ['./add-item.page.scss'],
})
export class AddItemPage {

  webpage: any;
  searchbarResults$: Subscription | undefined;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  messageSuccess: any;
  messageError: any;
  locale: any;
  text: any = {};
  name: any = '';
  imageUrl: string | ArrayBuffer | null = null;
  imageForm: FormGroup;

  constructor(
    private title: Title,
    private meta: Meta,
    private webpageService: WebpageService,
    private route: ActivatedRoute,
    public platform: Platform,
    public textService: TextService
  ) {

    this.route.url.subscribe(async segments => {
      this.locale = segments[0].path;
      this.webpage = await this.webpageService.getWebpageData(this.locale);
      this.webpageService.setLocale(this.locale);
      this.translateTexts();
      this.setMetaTags();
      this.webpageService.initializeSearchbar(this.locale);
    })

    this.imageForm = new FormGroup({
      name: new FormControl('', [Validators.required]), // Add your validators as necessary
      image: new FormControl(null, [Validators.required]) // Assuming the image is required; adjust as needed
    });

  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => { searchbarItem.img1x = `/assets/images/${searchbarItem.s}.jpg`;});
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  setMetaTags() {
    this.title.setTitle(this.text.pageTitle);
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
  }

  async onSubmitContactForm() {
    if (this.imageForm.valid) {
      this.messageSuccess = this.text.messageSuccess;
     /* const response:any = await firstValueFrom(this.webpageService.sendMessageViaImageForm(this.imageForm.value));
      if (response.status === 'OK') {
      }
      else {
        this.messageError = this.text.messageError;
      }*/
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  translateTexts() {
    this.text.pageTitle = this.textService.getTextLocale('pageTitle', 'addItem', this.locale);
    this.text.uploadSlogan = this.textService.getTextLocale('uploadSlogan', 'addItem', this.locale);
    this.text.messageError = this.textService.getTextLocale('messageError', 'addItem', this.locale);
    this.text.messageSuccess = this.textService.getTextLocale('messageSuccess', 'addItem', this.locale);
    this.text.uploadButtonText = this.textService.getTextLocale('uploadButtonText', 'addItem', this.locale);
    this.text.messageLabel = this.textService.getTextLocale('messageLabel', 'addItem', this.locale);
    this.text.messagePlaceholder = this.textService.getTextLocale('messagePlaceholder', 'addItem', this.locale);
    this.text.nameLabel = this.textService.getTextLocale('nameLabel', 'addItem', this.locale);
    this.text.namePlaceholder = this.textService.getTextLocale('namePlaceholder', 'addItem', this.locale);
    this.text.emailLabel = this.textService.getTextLocale('emailLabel', 'addItem', this.locale);
    this.text.emailPlaceholder = this.textService.getTextLocale('emailPlaceholder', 'addItem', this.locale);
  }


}
