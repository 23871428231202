import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy} from '@angular/router';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { WebpageService } from '../../services/web.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.page.html',
  styleUrls: ['./items-list.page.scss'],
})
export class ItemsListPage {

  searchbarResults$: Subscription | undefined;
  category: any;
  items: any[] = [];
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  itemsPerPage = 9;
  maxItems:any;
  breadcrumbs: any[] | any = [];
  images = {};
  imageAppUrl: any;
  currentItem:any;
  categorySlug: any;
  itemPath:any;
  paramMapSubscription: any;
  webpage: any;
  locale:any

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routeReuseStrategy: RouteReuseStrategy,
    private webpageService: WebpageService
  ) {

    // musi byc false inaczej nie działa
    this.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route.url.subscribe(async segments => {
      this.locale = segments[0].path;
      this.webpage = await this.webpageService.getWebpageData(segments[0].path);
      this.webpageService.setLocale(this.locale);
      // options: en/articles / tattoo-body-parts // tattoo-styles
      let slug;
      if (segments.length === 2) {slug = segments[1].path; }
      if (segments.length === 3) {slug = segments[2].path; }
      if (segments.length === 4) {slug = segments[3].path; }
      if (segments.length === 5) {slug = segments[4].path; }
      if (segments.length === 6) {slug = segments[5].path; }
      this.category = await this.webpageService.getItem(slug, `${this.locale}-categories`);
      await this.webpageService.setMetaTags(this.category);

      this.maxItems = this.category.children.length;
      this.breadcrumbs = this.category.breadcrumbs;
      this.items = this.category.children;
      this.items.forEach((item: any) => {
        item.img1x = this.webpageService.transformFilename(item.image);
      });
      this.imageAppUrl = `/assets/imagesapp/${this.category.english_slug}.jpg`;
      this.webpageService.initializeSearchbar(this.locale);
      if (!this.category) { this.router.navigateByUrl('/') }
    });
  }



  ionViewWillEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
    this.paramMapSubscription?.unsubscribe();
  }

  async openSearchbarItem(item: any) { await this.webpageService.openSearchbarItem(item, this.locale);  }

  getSlug(params:any) {
    if (params.slug1) {
      if (params.slug2) {
        if (params.slug3) {
          if (params.slug4) {
            return params.slug4;
          }
          else { return params.slug3;}
        }
        else { return params.slug2 }
      }
      else { return params.slug1; }
    }
    else {
      if(this.locale !== 'en') {
        this.router.navigateByUrl(`/${this.locale}`);
      }
      else { this.router.navigateByUrl('/'); }
    }
  }

  loadMoreData(ev:any) {
    this.itemsPerPage = this.itemsPerPage + 6;
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
      if (this.items.length > this.maxItems) {
        ev.target.disabled = true;
      }
    }, 500);
  }

}
