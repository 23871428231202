<ion-row class="header-space"></ion-row>

<ion-content>
    <ion-row>
      <ion-col class="ads" size="2"></ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" class="ion-no-padding">

        <ion-list role="list" *ngIf="searchbarInputLength === 0">

          <div class="h1-container" role="listitem">
            <h1 class="name extra-margin-top">{{text.pageTitle}}</h1>
          </div>

          <form [formGroup]="imageForm" (ngSubmit)="onSubmitContactForm()">
            <div class="upload-container">
              <label for="file-upload" style="margin: 0 auto; display: block; font-size: 1.4rem; padding-bottom: 2vh;">{{text.uploadSlogan}}</label>
              <input id="file-upload" style="margin: 0 auto" type="file" (change)="onFileSelected($event)" accept="image/*" />
              <div *ngIf="imageUrl" class="image-preview">
                <img [src]="imageUrl" alt="Image Preview"/>
              </div>
            </div>

            <ion-input
              type="text"
              id="name"
              formControlName="name"
              label="{{text.nameLabel}}"
              labelPlacement="stacked"
              fill="outline"
              [(ngModel)]="name"
              placeholder="{{text.namePlaceholder}}">
            </ion-input>

            <div class="message-success" *ngIf="messageSuccess">{{messageSuccess}}</div>
            <div class="message-error" *ngIf="messageError">{{messageError}}</div>

            <ion-button color="success" type="submit" expand="block" [disabled]="!imageUrl && name.length > 0">{{text.uploadButtonText}}</ion-button>
          </form>

      </ion-list>

      <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>
      <app-footer></app-footer>

      </ion-col>
    <ion-col class="ads" size="2"> </ion-col>
  </ion-row>
</ion-content>
