import { Component, Input} from '@angular/core';
import { TextService } from '../../services/text.service';
import { WebpageService } from '../../services/web.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [ ]
})
export class FooterComponent {

  //@Input() locale: any;
  locale$: any;
  locale: any;
  text:any = {};
  routes:any = {};

  constructor(public textService: TextService, private webpageService: WebpageService) {

    this.locale$ = this.webpageService.getLocale().subscribe(async locale => {
      if (locale !== null) {
        this.routes.contact = this.textService.getRouteLocale('contact', locale);
        this.routes.privacyPolicy = this.textService.getRouteLocale('privacyPolicy', locale);
        this.text.contact = this.textService.getTextLocale('contact', 'footer', locale);
        this.text.label = this.textService.getTextLocale('label', 'footer', locale);
        this.text.privacyPolicy = this.textService.getTextLocale('privacyPolicy', 'footer', locale);
      }
    });

  }




}
