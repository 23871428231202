import { Component, Input } from '@angular/core';
import { TextService } from '../../services/text.service';
import { WebpageService } from '../../services/web.service';


@Component({
  selector: 'related-categories',
  templateUrl: './related-categories.component.html',
  styleUrls: ['./related-categories.component.scss'],
  animations: [ ]
})
export class RelatedCategoriesComponent {

  @Input() relatedCategories: any;
  text: any = {};
  locale$:any;
  locale: any;

  constructor(public textService: TextService, private webpageService: WebpageService) {

    this.locale$ = this.webpageService.getLocale().subscribe(async locale => {
      if (locale !== null) {
        this.locale = locale;
        this.translateTexts();
      }
    })
  }

  translateTexts() {
    this.text.subheading = this.textService.getTextLocale('subheading', 'relatedCategories', this.locale);
    this.text.ariaLabel = this.textService.getTextLocale('ariaLabel', 'relatedCategries', this.locale);
  }

  ngOnDestroy() { this.locale$.unsubscribe(); }


}
