import { Component } from '@angular/core';
import { InfiniteScrollCustomEvent} from '@ionic/angular';
import { Meta, Title } from '@angular/platform-browser';
import { WebpageService } from '../../services/web.service';
import { Subscription } from 'rxjs';
import { RouteReuseStrategy, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.page.html',
  styleUrls: ['./categories.page.scss'],
})
export class CategoriesPage {

  webpage: any;
  searchbarResults$: Subscription | undefined;
  categories:any;
  items: any;
  articles: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  currentItem: any;
  locale: any;
  maxCategories:any;
  itemPath: any;
  articlePath: any;
  category: any;

  constructor(
    private routeReuseStrategy: RouteReuseStrategy,
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private webpageService: WebpageService,
  ) {

    this.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route.url.subscribe(async segments => {
      if (segments.length === 0) { this.locale = 'en'; }
      if (segments.length > 0) { this.locale = segments[0].path; }
      let slug = segments[1].path;
      this.category = await this.webpageService.getItem(slug, `${this.locale}-categories`);

      this.webpage = await this.webpageService.getWebpageData(this.locale);
      this.webpageService.setLocale(this.locale);
      this.webpageService.setMetaTags(this.category);
      this.webpageService.initializeSearchbar(this.locale);
      let results = await this.webpageService.getItem(`${this.locale}-roots`, `roots`);
      this.items = results;
      this.items.forEach((item: any) => {
        item.img300 = `/assets/images/${item.english_slug}-300.jpg`;
      });
    })
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  async openSearchbarItem(item: any) { await this.webpageService.openSearchbarItem(item, this.locale); }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  loadMoreData(ev:any) {
    // Disable infinite scroll if the entire list has been loaded
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
      if (this.categories.length > this.maxCategories) {
        ev.target.disabled = true;
      }
    }, 500);
  }

}
