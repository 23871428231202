<div class="h2-container">
  <h2 class="related-items-heading">{{text.subheading}}</h2>
</div>
<ion-row style="margin-top:1.5vh">
  <ion-col class="default-zoom-color zoom-effect" size-xs="12" size-sm="6" size-md="6" size-lg="3" *ngFor="let relatedItem of relatedItems" role="list">
    <div role="listitem" class="item-container">
      <a class="related-item-image-container" [routerLink]="'/' + relatedItem.path" [state]="{slug: relatedItem.slug}" [attr.aria-label]="text.ariaLabel + relatedItem?.name" tabindex="0" role="button">
        <ion-skeleton-text [ngClass]="{'hidden': relatedItem.loaded}" [animated]="true"></ion-skeleton-text>
        <ion-img alt="{{relatedItem?.name}}" src="{{relatedItem?.img1x}}" [ngClass]="{'fadeIn': relatedItem.loaded}" (ionImgDidLoad)="relatedItem.loaded = true"></ion-img>
      </a>
      <span class="related-item-name">{{relatedItem.name}}</span>
    </div>
  </ion-col>
</ion-row>