
<ion-grid>
  <ion-row>
    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" offset-lg="2" class="ion-no-padding">
      <div class="top-header">
        <nav class="desktop-menu" *ngIf="!isMobile">
          <a [routerLink]="homePath"  [attr.aria-label]="text.arialabel" role="button" tabindex="0">
            <img class="desktop-logo" alt="Logo" src="assets/logo-100.jpg" />
          </a>
          <div *ngFor="let category of rootCategories" routerDirection="root" (click)="openItem(category)" lines="none" detail="false" routerLinkActive="selected">
            {{ category.shortname}}
          </div>
          <div (click)="goToAddImage()">
            <ion-icon style="font-size: 1.8rem; margin-top: -4px;" src="assets/icons-custom/add-circle-outline.svg"></ion-icon><span>{{text.addImage}}</span>
          </div>
        </nav>
        <nav class="mobile-menu" *ngIf="isMobile">
          <div *ngIf="webpageService.showSearchBar" class="mobile-searchbar-container">
            <ion-searchbar
            search-icon="null"
            [debounce]="500"
            placeholder="{{text.placeholderText}}"
            (ionInput)="searchItem($event)"
            (ionClear)="clearSearchbar()"
            value="{{searchbarInput}}">
            </ion-searchbar>
            <ion-icon [attr.aria-label]="text.ariaButtonCloseSearch" role="button" tabindex="0"
            class="close-searchbar-icon"
            name="search"
            src="assets/icons-custom/arrow-back-outline.svg"
            (click)="toggleSearch()">
            </ion-icon>
          </div>
          <div class="mobile-logo-container">
            <a [routerLink]="homePath"  [attr.aria-label]="text.arialabel" role="button" tabindex="0">
              <img class="mobile-logo" alt="Logo" src="assets/logo-100.jpg" />
            </a>
          </div>
          <div class="title"> {{text.websiteName}} </div>
          <div class="menu-icons">
            <ion-button *ngIf="isMobile" fill="clear" class="search-icon" (click)="toggleSearch()" [attr.aria-label]="text.ariaButtonSearch" role="button" tabindex="0">
              <ion-icon src="assets/icons-custom/search-circle-outline.svg"></ion-icon>
            </ion-button>
            <ion-button *ngIf="isMobile" fill="clear" class="menu-icon" (click)="toggleMenu()" [attr.aria-label]="text.ariaButtonMenu" role="button" tabindex="0">
              <ion-icon src="assets/icons-custom/menu-outline.svg"></ion-icon>
            </ion-button>
          </div>
        </nav>
      </div>
      <ion-toolbar *ngIf="!isMobile">
        <ion-searchbar
        [debounce]="500"
        placeholder="{{text.placeholderText}}"
        (ionInput)="searchItem($event)"
        (ionClear)="clearSearchbar()"
        value="{{searchbarInput}}">
        </ion-searchbar>
      </ion-toolbar>
    </ion-col>
  </ion-row>

  <div class="side-menu-overlay" *ngIf="menuOpen" (click)="toggleMenu()"></div>
  <nav class="side-menu" [ngClass]="{'open': menuOpen}">
    <ion-content style="height: 100%;">
      <ion-list role="list" style="margin-bottom: 7vh">
        <ion-list-header role="listitem">
          <ion-label>Menu</ion-label>
          <ion-button fill="clear" class="close-side-menu-icon" (click)="toggleMenu()" [attr.aria-label]="text.ariaButtonCloseMenu" role="button" tabindex="0">
            <ion-icon src="assets/icons-custom/close-outline.svg"></ion-icon>
          </ion-button>
        </ion-list-header>

        <ion-accordion-group #accordionGroup role="listitem">
          <ion-accordion *ngFor="let item of mobileMenuCategories" [value]="item.shortname" toggleIconSlot="start">
              <ion-item slot="header" role="button" tabindex="0" aria-expanded="false" aria-controls="contentPanel1">
                <ion-label>{{ item.shortname }}</ion-label>
              </ion-item>
            <ion-list slot="content">
              <ion-accordion-group>
                <ng-container *ngFor="let child of item.children">
                  <ion-accordion *ngIf="child.children.length > 0" [value]="child.shortname" toggleIconSlot="start">
                    <ion-item slot="header">
                      <ion-label>{{ child.shortname }}</ion-label>
                    </ion-item>
                    <ion-list slot="content">
                      <ion-item *ngFor="let grand of child.children" (click)="goTo($event, grand.path)">
                        <ion-icon slot="start" class="icon" src="assets/icons-custom/chevron-forward-outline.svg"></ion-icon>
                        <ion-label>{{ grand.shortname }}</ion-label>
                      </ion-item>
                    </ion-list>
                  </ion-accordion>
                  <ion-item class="expanded-item" *ngIf="child.children.length === 0" (click)="goTo($event, child.path)">
                    <ion-icon slot="start" class="icon" src="assets/icons-custom/chevron-forward-outline.svg"></ion-icon>
                    <ion-label>{{ child.shortname }}</ion-label>
                  </ion-item>
                </ng-container>
              </ion-accordion-group>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>
        <ion-item role="listitem" (click)="goTo($event, this.routes.privacyPolicy)">
          <ion-icon slot="start" class="icon" src="assets/icons-custom/chevron-forward-outline.svg"></ion-icon>
          <ion-label>{{ text.privacyPolicy }}</ion-label>
        </ion-item>
        <ion-item role="listitem" (click)="goTo($event, this.routes.contact)">
          <ion-icon slot="start" class="icon" src="assets/icons-custom/chevron-forward-outline.svg"></ion-icon>
          <ion-label>{{ text.contact }}</ion-label>
        </ion-item>
        <ion-item role="listitem" (click)="goToAddImage()">
          <ion-icon slot="start" class="icon" src="assets/icons-custom/add-circle-outline.svg"></ion-icon>
          <ion-label>{{ text.addImage }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </nav>
</ion-grid>