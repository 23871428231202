import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebpageService } from '../../services/web.service';
import { Subscription } from 'rxjs';
import { InfiniteScrollCustomEvent} from '@ionic/angular';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.page.html',
  styleUrls: ['./articles-list.page.scss'],
})
export class ArticlesListPage {

  webpage:any;
  locale: any;
  isBrowser: any;
  searchbarResults$: Subscription | undefined;
  category: any;
  items: any[] = [];
  itemsPerPage = 12;
  maxItems: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  pageItems = 100;
  breadcrumbs: any[] = [];
  paramMapSubscription: any;
  images = {};
  imageAppUrl: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private webpageService: WebpageService,

  ) {

    this.route.queryParams.subscribe(async params => {
      if (this.router.getCurrentNavigation()?.extras.state?.['category']) {
        let categorySlug = this.router.getCurrentNavigation()?.extras.state?.['category'].slug;
        this.locale = this.router.getCurrentNavigation()?.extras.state?.['category'].locale;
        this.webpage = await this.webpageService.getWebpageData(this.locale);
        this.webpageService.setLocale(this.locale);
        this.category = await this.webpageService.getItem(categorySlug, `${this.locale}-categories`);

        this.imageAppUrl = `${this.webpage.cdn}/assets/imagesapp/${this.category.english_slug}.jpg`;

        this.maxItems = this.category.children.length;
        this.items = this.category.children;
        this.items.forEach((item: any) => {
          item.img1x = this.webpageService.transformFilename(item.image);
        });
        this.webpageService.setMetaTags(this.category);
        this.webpageService.initializeSearchbar(this.locale);
      }
      else {
        this.route.url.subscribe(async segments => {
          this.locale = segments[0].path;
          this.webpage = await this.webpageService.getWebpageData(this.locale);
          this.webpageService.setLocale(this.locale);
          if (segments.length === 3) {
            const segmentValueSecond:any = segments[2].path;
            this.category = await this.webpageService.getItem(segmentValueSecond, `${this.locale}-categories`);
            if (this.category.inside === 'ARTICLES') { this.category.path = `${this.locale}/${this.webpage.articleItemSlug}` }
            this.maxItems = this.category.children.length;
            this.items = this.category.children;
            this.items.forEach((item: any) => {
              item.img1x = this.webpageService.transformFilename(item.image);
            });
            this.imageAppUrl = `${this.webpage.cdn}/assets/imagesapp/${this.category.english_slug}.jpg`;

            this.webpageService.setMetaTags(this.category);
            this.webpageService.initializeSearchbar(this.locale);

          }
          else {
            this.goToMainPage();
          }
        })
      }
    })
  }

  goToMainPage() {
    if(this.locale === 'en') { this.router.navigateByUrl('/');}
    else { this.router.navigateByUrl(`/${this.locale}`) }
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
    this.paramMapSubscription?.unsubscribe();
  }

  async openSearchbarItem(item:any) { await this.webpageService.openSearchbarItem(item, this.locale); }


  loadMoreData(ev:any) {
    this.itemsPerPage = this.itemsPerPage + 12;
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
      if (this.items.length > this.maxItems) {
        ev.target.disabled = true;
      }
    }, 500);
  }
}
