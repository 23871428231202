<ion-row class="header-space"></ion-row>

<ion-content>
  <ion-row>
    <ion-col class="ads" size="2"></ion-col>
    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" class="ion-no-padding">

      <ion-list role="list" *ngIf="searchbarInputLength === 0">

        <article>
          <div class="h1-container" role="listitem">
            <h1 class="name extra-margin-top">{{text.pageTitle}}</h1>
          </div>
          <ng-container *ngFor="let section of text.sections; let i = index">
            <div class="contents" *ngIf="section.subheading" (click)="scrollToElement(section.subheadingRef)">
              <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{section.subheading}}
            </div>
          </ng-container>
          <div class="introduction" *ngIf="text?.introduction" [innerHTML]="text.introduction"></div>
          <ng-container *ngFor="let section of text.sections; let i = index">
            <div id="{{section.subheadingRef}}" #subheadingRef *ngIf="section.subheading">
              <h2>{{section.subheading}}</h2>
            </div>
            <div *ngIf="section.section" [innerHTML]="section.section"></div>
          </ng-container>
        </article>

        <app-footer></app-footer>

      </ion-list>

      <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>

      </ion-col>
    <ion-col class="ads" size="2"> </ion-col>
  </ion-row>
</ion-content>
