<div class="ads-image-container" *ngIf="ad">
  <a [href]="ad?.DetailPageURL" role="button" target='_blank' rel='nofollow'>
    <ion-skeleton-text [animated]="true" [ngClass]="{'hidden': ad?.loaded}"></ion-skeleton-text>
    <ion-img [ngClass]="{'fadeIn': ad?.loaded}" [src]="ad?.Images?.[0]" alt="Ad" (ionImgDidLoad)="ad.loaded = true"></ion-img>
    <div class="action-button-container">
      <div class="price-container">
        <img src="assets/icons-custom/amazon.webp" alt="Amazon">
        <span class="price">
          <span class="currency-symbol">{{currencySymbol}}</span>{{ad?.Price | number:'1.2-2'}}
        </span>
      </div>
      <ion-button size="small" expand="full" fill="solid" color="action">{{adActionButton}}</ion-button>
    </div>
  </a>
</div>