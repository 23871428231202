// privacy-policy-popup.component.ts
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TextService } from '../../services/text.service';
import { WebpageService } from '../../services/web.service';

@Component({
  selector: 'app-privacy-policy-popup',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {

  showPopup: boolean = true;
  isBrowser: any;
  locale$: any;
  locale: any;
  text:any = {};

  constructor(@Inject(PLATFORM_ID) platformId: Object, public textService: TextService, private webpageService: WebpageService) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.locale$ = this.webpageService.getLocale().subscribe(async locale => {
      if (locale !== null) {
        this.locale = locale;
        this.translateTexts();
      }
    })
  }

  dismiss() {
    if (this.isBrowser) {
      this.showPopup = false;
      localStorage.setItem('isPrivacyPolicyAccepted', 'true');
    }
  }
  ngOnInit() {
    if (this.isBrowser) {
      this.showPopup = !localStorage.getItem('isPrivacyPolicyAccepted');
     // this.showPopup = true;
    }
  }

  translateTexts() {
    this.text.slogan = this.textService.getTextLocale('slogan', 'privacyPolicyPopup', this.locale);
  }
}