import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { InfiniteScrollCustomEvent} from '@ionic/angular';
import { Meta, Title } from '@angular/platform-browser';
import { WebpageService } from '../../services/web.service';
import { PreloadService } from 'src/services/preload.service';
import { Subscription } from 'rxjs';
import { RouteReuseStrategy, ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage {

  webpage: any;
  searchbarResults$: Subscription | undefined;
  categories:any;
  items: any[] = [];
  articles: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  currentItem: any;
  locale: any;
  maxCategories:any;
  itemPath: any;
  articlePath: any;
  subheadingA:any;
  subheadingB:any;
  subheadingC:any;
  buttonTextA: any;
  buttonTextB: any;
  buttonTextC: any;
  itemsPerPage = 24;
  showCategories = false;
  showArticles = false;
  hreflangs:any;
  isBrowser: any;

  constructor(
    private routeReuseStrategy: RouteReuseStrategy,
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private router: Router,
    private webpageService: WebpageService,
    private preloadService: PreloadService,
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.routeReuseStrategy.shouldReuseRoute = () => false;

    this.isBrowser = isPlatformBrowser(platformId);

    this.route.url.subscribe(async segments => {
      if (segments.length === 0) { this.locale = 'en'; }
      if (segments.length > 0) { this.locale = segments[0].path; }
      this.translateElements(this.locale);
      this.webpage = await this.webpageService.getWebpageData(this.locale);
      this.hreflangs = this.webpageService.getHreflangsForMainPage();
      this.itemPath = `${this.locale}/${this.webpage.itemSlug}`;
      this.articlePath = `${this.locale}/${this.webpage.articleItemSlug}`
      this.webpageService.setLocale(this.locale);
      this.setMetaTagsForMainPage();
      this.webpageService.initializeSearchbar(this.locale);
      const randomNumber = this.getRandomNumber();
      let results = await this.webpageService.getItem(`mainpage${randomNumber}`, `${this.locale}-mainpages`);
      this.categories = results.categories;
      // console.log(this.categories);
      this.items = results.items;
      this.items.forEach((item: any) => {
        item.img1x = this.webpageService.transformFilename(item.image);
      });
      this.articles = results.articles;
      this.articles.forEach((item: any) => {
        item.img1x = this.webpageService.transformFilename(item.image);
      });
      this.maxCategories = this.categories.length;
      this.categories.forEach((item:any) => {
        item.img1x = this.webpageService.transformFilename(item.image);
      });
    })
  }

  getRandomNumber(min: number = 1, max: number = 100): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
 }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  async openSearchbarItem(item: any) { await this.webpageService.openSearchbarItem(item, this.locale); }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  openThemes() {
    let path;
    if (this.locale === 'de') { path = 'de/tattoo-themen'}
    else if(this.locale === 'es') { path = 'es/temas-tatuajes'}
    else if(this.locale === 'fr') { path = 'fr/themes-tatouages'}
    else if(this.locale === 'it') { path = 'it/temi-tatuaggi'}
    else if(this.locale === 'pl') { path = 'pl/motywy-tatuaze'}
    else path = 'en/tattoo-themes';
    this.router.navigate(['/' + path])
  }

  openArticles() {
    let path;
    if (this.locale === 'de') { path = 'de/artikel-tattoos'}
    else if(this.locale === 'es') { path = 'es/articulos-tatuajes'}
    else if(this.locale === 'fr') { path = 'fr/articles-tatouages'}
    else if(this.locale === 'it') { path = 'it/articoli-tatuaggi'}
    else if(this.locale === 'pl') { path = 'pl/artykuly-tatuaze'}
    else path = 'en/articles';
    this.router.navigate(['/' + path])
  }

  setMetaTagsForMainPage() {
    this.title.setTitle(this.webpage.metaTitle);
    this.meta.removeTag("rel='alternate'");
    const canonical = `https://${this.webpage.name}`;
    this.preloadService.setCanonicalURL(canonical);
    this.preloadService.removeAlternateURLs();
    for (let alternate of this.hreflangs) {
      if (alternate.hreflang !== 'x-default') {
        this.preloadService.setAlternateURL(alternate);
      }
    }
    this.meta.updateTag({name:'google-site-verification', content: '-DhWtoT-xLVfKf4-4wU4H2ts4uU1AVgxRlssnkNV2zc' });
    this.meta.updateTag({name:'description', content: this.webpage.metaDescription});
    this.meta.updateTag({name:'keywords', content: this.webpage.metaKeywords});
    //Facebook
    this.meta.updateTag({name:'og:type', content: 'website'});
    this.meta.updateTag({name:'og:site_name', content: this.webpage.metaWebpage});
    this.meta.updateTag({name:'og:url', content: `https://${this.webpage.metaWebpage}` });
    this.meta.updateTag({name:'og:title', content: this.webpage.metaTitle });
    this.meta.updateTag({name:'og:description', content: this.webpage.metaDescription });
    this.meta.updateTag({name:'og:image', content: this.webpage.metaImage}); // Recommend 1200×628

    this.meta.updateTag({name:'twitter:card', content: "summary_large_image"});
    this.meta.updateTag({name:'twitter:url', content: `https://${this.webpage.metaWebpage}`});
    this.meta.updateTag({name:'twitter:title', content: this.webpage.metaTitle});
    this.meta.updateTag({name:'twitter:description', content: this.webpage.metaDescription});
    this.meta.updateTag({name:'twitter:image', content: this.webpage.metaImage300});
  }

  loadMoreData(ev:any) {
    this.itemsPerPage = this.itemsPerPage + 12;
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
      if (this.items.length > 30) {
        ev.target.disabled = true;
        this.showCategories = true;
        this.showArticles = true;
      }
    }, 500);
  }

  translateElements(locale:any) {
    if (locale === 'it') {
      this.subheadingA = 'TATUAGGI RECENTI';
      this.subheadingB = 'CATEGORIE POPOLARI';
      this.subheadingC = 'ARTICOLI INTERESSANTI';
      this.buttonTextA = 'SFOLGLIA TATUAGGI PER TEMI';
      this.buttonTextB = 'VEDI TUTTE LE CATEGORIE';
      this.buttonTextC = 'VEDI TUTTI GLI ARTICOLI';
    }
    else if (locale === 'de') {
      this.subheadingA = 'NEUESTE TATTOOS';
      this.subheadingB = 'BELIEBTE KATEGORIEN';
      this.subheadingC = 'INTERESSANTE ARTIKEL';
      this.buttonTextA = 'TATTOOS NACH THEMEN DURCHSUCHEN';
      this.buttonTextB = 'ALLE KATEGORIEN ANSEHEN';
      this.buttonTextC = 'ALLE ARTIKEL ANSEHEN';
    }
    else if (locale === 'fr') {
      this.subheadingA = 'TATOUAGES RÉCENTS';
      this.subheadingB = 'CATÉGORIES POPULAIRES';
      this.subheadingC = 'ARTICLES INTÉRESSANTS';
      this.buttonTextA = 'PARCOURIR LES TATOUAGES PAR THÈMES';
      this.buttonTextB = 'VOIR TOUTES LES CATÉGORIES';
      this.buttonTextC = 'VOIR TOUS LES ARTICLES';
    }
    else if (locale === 'pl') {
      this.subheadingA = 'NAJNOWSZE TATUAŻE'
      this.subheadingB = 'POPULARNE KATEGORIE'
      this.subheadingC = 'CIEKAWE ARTYKUŁY'
      this.buttonTextA = 'PRZEGLĄDAJ TATUAŻE WEDŁUG TEMATÓW'
      this.buttonTextB = 'ZOBACZ WSZYSTKIE KATEGORIE'
      this.buttonTextC = 'ZOBACZ WSZYSTKIE ARTYKUŁY'
    }
    else if (locale === 'es') {
      this.subheadingA = 'TATUAJES RECIENTES'
      this.subheadingB = 'CATEGORÍAS POPULARES'
      this.subheadingC = 'ARTÍCULOS INTERESANTES'
      this.buttonTextA = 'EXPLORAR TATUAJES POR TEMAS'
      this.buttonTextB = 'VER TODAS LAS CATEGORÍAS'
      this.buttonTextC = 'VER TODOS LOS ARTÍCULOS'
    }
    else {
      this.subheadingA = 'RECENT TATTOOS';
      this.subheadingB = 'POPULAR CATEGORIES';
      this.subheadingC = 'INTERESTING ARTICLES';
      this.buttonTextA = 'BROWSE TATTOOS BY THEMES';
      this.buttonTextB = 'SEE ALL CATEGORIES';
      this.buttonTextC = 'SEE ALL ARTICLES';
    }
  }

}
