<ion-row class="header-space"></ion-row>
<ion-row>
  <ion-col class="ads" size="2"></ion-col>
  <ion-col size-md="12" size-lg="8" class="ion-no-padding">
    <div class="breadcrumbs-container">
      <span class="breadcrumbs" *ngFor="let breadcrumb of item?.breadcrumbs; let last = last">
        <a rel="nofollow" [routerLink]="'/' + breadcrumb?.path" >
          <small>{{ breadcrumb?.name }}</small>
          <small *ngIf="!last"> > </small>
        </a>
      </span>
    </div>
  </ion-col>
  <ion-col class="ads" size="2"></ion-col>
</ion-row>

  <ion-content>
    <ion-row>
      <ion-col class="ads" size="2"></ion-col>
      <ion-col size-md="12" size-lg="8" class="ion-no-padding">
        <ion-list role="list" *ngIf="searchbarInputLength === 0" style="padding-top: 0">
          <div class="h1-container" role="listitem"><h1 class="name">{{item?.name}}</h1></div>
          <div class="main-image-container" role="listitem">
            <ion-skeleton-text class="main-image" [ngClass]="{'hidden': item?.mainloaded}" [animated]="true"></ion-skeleton-text>
            <ion-img alt="{{item?.imageAlt}}" class="main-image" src="{{item?.img}}" (ionImgDidLoad)="item.mainloaded = true"></ion-img>
          </div>
          <div role="listitem">
            <share-buttons [webpageCDN]="webpage?.cdn" [item]="item"></share-buttons>
            <ion-button class="print" expand="block" size="large" fill="solid" color="medium" (click)="print()">
              <ion-icon slot="start" src="{{'assets/icons-custom/printer.svg'}}" aria-hidden="true"></ion-icon>
              PRINT
            </ion-button>
          </div>
          <ion-row role="listitem">
            <ion-col size-xs="12" size-sm="6">
              <ad-image
                [ad]="ads[0]"
                [currencySymbol]="currencySymbol$ | async"
                [adActionButton]="text.adActionButton">
              </ad-image>
            </ion-col>
            <ion-col size-xs="12" size-sm="6">
              <ad-image
                [ad]="ads[1]"
                [currencySymbol]="currencySymbol$ | async"
                [adActionButton]="text.adActionButton">
              </ad-image>
            </ion-col>
          </ion-row>
          <div role="listitem" class="description" [attr.aria-label]="'Item description'">{{item?.description}}</div>

          <related-items role="listitem" [relatedItems]="relatedItems" ></related-items>

          <related-categories role="listitem" [relatedCategories]="relatedCategories" ></related-categories>

        </ion-list>

        <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>

        <app-footer *ngIf="item"></app-footer>

    </ion-col>
    <ion-col class="ads" size="2"></ion-col>
  </ion-row>
</ion-content>
