import { Component, Inject, QueryList, ElementRef, ViewChildren, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { WebpageService } from '../../services/web.service';
import { PreloadService } from '../../services/preload.service';
import { LocationService } from '../../services/location.service';
import { TextService } from '../../services/text.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { Platform } from '@ionic/angular';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-article',
  templateUrl: './article.page.html',
  styleUrls: ['./article.page.scss'],
})
export class ArticlePage {

  @ViewChildren('subheadingRef', { read: ElementRef }) subheadingRefs: QueryList<ElementRef> | any;

  searchbarResults$: Subscription | undefined;
  item: any;
  category: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  breadcrumbs: any[] = [];
  selectedImage: any;
  isBrowser: any;
  relatedItems: any;
  relatedCategories: any;
  webpage: any;
  locale: any;
  avatar: any;
  ads: any = {};
  currencySymbol$:any;
  text: any = {};

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private router: Router,
    private route: ActivatedRoute,
    private webpageService: WebpageService,
    private preloadService: PreloadService,
    private locationService: LocationService,
    private textService: TextService,
    private routeReuseStrategy: RouteReuseStrategy,
    private sanitizer: DomSanitizer,
    public platform: Platform,
  ) {

    this.isBrowser = isPlatformBrowser(platformId);
    this.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route.url.subscribe(async segments => {
      this.locale = segments[0].path;
      this.translateTexts();
      this.webpage = await this.webpageService.getWebpageData(this.locale);
      this.webpageService.setLocale(this.locale);
      let itemSlug;
      if (this.router.getCurrentNavigation()?.extras.state?.['item']) {
        let child= this.router.getCurrentNavigation()?.extras.state?.['item'];
        itemSlug = child.slug;
      }
      if (!this.router.getCurrentNavigation()?.extras.state?.['item']) {
        itemSlug = this.route.snapshot.paramMap.get('itemSlug');
      }
      //
      await this.getItemData(itemSlug); // slug + locale
    });
  }

  async getItemData(itemSlug:any) {
    this.item = await this.webpageService.getItem(itemSlug, `${this.locale}-articles`);
    this.item.img = `/assets/images/${this.item.image}`;
    this.avatar = `/assets/avatars/${this.item.author.slug}.webp`;
    this.preloadService.preloadImages([this.item.img, this.avatar]);
    this.sanitizeContent();
    this.getRelatedItemsAndCategories();
    this.webpageService.setMetaTags(this.item);
    this.webpageService.initializeSearchbar(this.locale);
    this.webpageService.clearSearchbarInput();

    if (!this.isBrowser) {
      const data = await lastValueFrom(this.locationService.ads$);
      this.ads = this.webpageService.getRandomAds(data, 13);
    }
    if (this.isBrowser) {
      this.currencySymbol$ = this.locationService.getCurrencySymbol();
      this.locationService.ads$.subscribe(data => {
        this.ads = this.webpageService.getRandomAds(data, 13);
      });
    }

    if (!this.item) { this.router.navigateByUrl('/') }
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  scrollToElement(subheadingRefName: string) {
    const elementToScroll = this.subheadingRefs.find((ref:any) => ref.nativeElement.id === subheadingRefName);
    if (elementToScroll) {
      elementToScroll.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  sanitizeContent() {
    this.item.section1 = this.sanitizer.bypassSecurityTrustHtml(this.item.section1);
    this.item.section1_summary = this.sanitizer.bypassSecurityTrustHtml(this.item.section1_summary);
    this.item.section2 = this.sanitizer.bypassSecurityTrustHtml(this.item.section2);
    this.item.section2_summary = this.sanitizer.bypassSecurityTrustHtml(this.item.section2_summary);
    this.item.section3 = this.sanitizer.bypassSecurityTrustHtml(this.item.section3);
    this.item.section3_summary = this.sanitizer.bypassSecurityTrustHtml(this.item.section3_summary);
    this.item.section4 = this.sanitizer.bypassSecurityTrustHtml(this.item.section4);
    this.item.section4_summary = this.sanitizer.bypassSecurityTrustHtml(this.item.section4_summary);
    this.item.section5 = this.sanitizer.bypassSecurityTrustHtml(this.item.section5);
    this.item.section5_summary = this.sanitizer.bypassSecurityTrustHtml(this.item.section5_summary);
  }

  getRelatedItemsAndCategories() {
    this.relatedItems = this.item.relatedItems;
    this.relatedItems.forEach((item:any) => {
      item.img1x = this.webpageService.transformFilename(item.image);
      item.path = `${this.locale}/${this.webpage.articleItemSlug}/${item.slug}`;
    });
    this.relatedCategories = this.item.relatedCategories;
    this.relatedCategories.forEach((item:any) => {
      item.img1x = this.webpageService.transformFilename(item.image);
    });
  }

  translateTexts() {
    this.text.adActionButton = this.textService.getTextLocale('adActionButton', 'item', this.locale);
  }

}
