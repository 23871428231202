import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { WebpageService } from './web.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {


  private marketplaceSubject = new BehaviorSubject<string | null>(null);
  public currencySymbolSubject = new BehaviorSubject<string | null>(null);
  marketplace$ = this.marketplaceSubject.asObservable();
  private adsSubject = new BehaviorSubject<any>([]);
  ads$ = this.adsSubject.asObservable();
  private locationUrl = 'https://us-east1-webpanel-admin.cloudfunctions.net/myFunctions/getMarketplace'; // Replace with your actual token
  isBrowser: any;

  constructor(private http: HttpClient, private webpageService: WebpageService, @Inject(PLATFORM_ID) platformId: Object,) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  getUserLocation(): Observable<any> { return this.http.get<any>(this.locationUrl); }

  getAds(marketplace:any) { return firstValueFrom(this.http.get(`/assets/ads/${marketplace}.json`)); }

  async init() {
    if (this.isBrowser) {
      try {
        const response: any = await firstValueFrom(this.getUserLocation());
        // console.log(response);
        const marketplace = response.marketplace;
        this.setMarketplace(marketplace);
        const ads = await this.webpageService.getItem(marketplace, 'ads');
        this.setAds(ads);
        this.setCurrencySymbol(marketplace)
      } catch (error) {
        this.setMarketplace('US');
        const ads = await this.webpageService.getItem('US', 'ads');
        this.setAds(ads);
        this.setCurrencySymbol('US');
        console.error('Error fetching location', error);
      }
    }
  }

  setCurrencySymbol(marketplace: any) {
    //console.log('Marketplace: ', marketplace);
    let currencySymbol = '';

    switch(marketplace.toUpperCase()) {
      case 'US':
        currencySymbol = '$';
        break;
      case 'UK':
        currencySymbol = '£';
        break;
      case 'IT':
      case 'DE':
      case 'ES':
      case 'FR':
        currencySymbol = '€';
        break;
      case 'PL':
        currencySymbol = 'PLN';  // Symbol for Polish Złoty
        break;
      default:
        currencySymbol = ''; // Default value if marketplace is not recognized
    }
    //console.log(currencySymbol);
    this.currencySymbolSubject.next(currencySymbol);
  }

  getCurrencySymbol() { return this.currencySymbolSubject.asObservable().pipe();  }

  setMarketplace(value: string) { this.marketplaceSubject.next(value); }

  setAds(value: any) { this.adsSubject.next(value); }



}