
<ion-row class="header-space"></ion-row>
<ion-content>
  <ion-grid>
    <ion-row style="height: 100%">
      <ion-col class="ads" size="1"> </ion-col>
      <ion-col size-md="12" size-lg="10" class="main-column">

        <ion-list *ngIf="searchbarInputLength === 0" role="list">
          <div class="h1-container" role="listitem"><h1 class="name extra-margin-top">{{subheadingA}}</h1></div>
          <div class="home-grid">
            <div class="home-item" role="listitem" *ngFor="let item of items | slice:0:itemsPerPage; let i = index">
                <div [ngClass]="{'item-image-container-rectangle': i % 2 === 0 }" class="item-image-container">
                  <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}" ></ion-skeleton-text>
                  <a [routerLink]="'/' + itemPath + '/' + item.slug" [state]="{data: item}" [attr.aria-label]="'Open ' + item?.name" role="button" tabindex="0" >
                    <ion-img alt="{{item?.imageAlt}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
                  </a>
                </div>
            </div>

            <ion-infinite-scroll threshold="40%" (ionInfinite)="loadMoreData($event)">
              <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
            </ion-infinite-scroll>

          </div>
          <div role="listitem">
            <ion-button style="width: 100%; margin-top: 10vh;"  expand="block" size="medium" fill="solid" color="medium" (click)="openThemes()">
              {{buttonTextA}}
            </ion-button>
          </div>
          <ion-row *ngIf="showCategories">
            <div class="h1-container"><h1 class="name extra-margin-top">{{subheadingB}}</h1></div>
            <ion-col role="listitem" *ngFor="let category of categories" size-xs="12" size-sm="6" size-md="4">
              <a class="item-container" [routerLink]="'/' + category.path" [state]="{ data: category }" [attr.aria-label]="'Open ' + category?.name" role="button" tabindex="0" >
                 <div class="item-image-container">
                  <ion-skeleton-text [animated]="true" *ngIf="!category.loaded"></ion-skeleton-text>
                  <ion-img alt="{{category?.imageAlt}}" src="{{category?.img1x}}" [ngClass]="{'fadeIn': category.loaded}" (ionImgDidLoad)="category.loaded = true"></ion-img>
                </div>
                <span class="item-name">{{category?.name}}</span>
              </a>
            </ion-col>
          </ion-row>
          <div role="listitem">
            <ion-button style="width: 100%" expand="block" size="medium" fill="solid" color="medium" (click)="openThemes()">
              {{buttonTextB}}
            </ion-button>
          </div>

          <ion-row *ngIf="showArticles">
            <div class="h1-container"><h1 class="name extra-margin-top">{{subheadingC}}</h1></div>
            <ion-col role="listitem" *ngFor="let item of articles" size-xs="12" size-sm="6" size-md="4" size-lg="4">
              <div class="item-container" [routerLink]="'/' + articlePath + '/' + item.slug" [state]="{data: item}" [attr.aria-label]="'Open ' + item?.name" role="button" tabindex="0">
                 <div class="articles-list-image-container">
                  <ion-skeleton-text aria-hidden="true" [ngClass]="{'hidden': item.loaded}" [animated]="true"></ion-skeleton-text>
                  <ion-img alt="{{item?.imageAlt}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
                </div>
                <span class="item-name" [ngClass]="{'fadeIn': item.loaded}">{{item.name}}</span>
              </div>
            </ion-col>
          </ion-row>
          <div role="listitem">
            <ion-button style="width: 100%" expand="block" size="medium" fill="solid" color="medium" (click)="openArticles()">
              {{buttonTextC}}
            </ion-button>
          </div>

          <app-footer *ngIf="showCategories"></app-footer>
        </ion-list>

        <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>

      </ion-col>
      <ion-col class="ads" size="1"></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
