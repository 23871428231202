<ion-row class="header-space"></ion-row>
<ion-content>
  <ion-row>
    <ion-col class="ads" size="2"></ion-col>
    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" class="ion-no-padding">

      <ion-list role="list" *ngIf="searchbarInputLength === 0">
        <div class="h1-container" role="listitem">
          <h1 class="name extra-margin-top">{{text.pageTitle}}</h1>
        </div>

        <form [formGroup]="contactForm" (ngSubmit)="onSubmitContactForm()">

          <ion-input
            type="text"
            id="name"
            formControlName="name"
            label="{{text.nameLabel}}"
            labelPlacement="stacked"
            fill="outline"
            placeholder="{{text.namePlaceholder}}">
          </ion-input>

          <ion-input
            type="text"
            id="email"
            formControlName="email"
            label="{{text.emailLabel}}"
            labelPlacement="stacked"
            fill="outline"
            placeholder="{{text.emailPlaceholder}}">
          </ion-input>

          <ion-input class="input-message"
            type="text"
            id="message"
            formControlName="message"
            label="{{text.messageLabel}}"
            labelPlacement="stacked"
            fill="outline"
            placeholder="{{text.messagePlaceholder}}">
          </ion-input>

          <div class="message-success" *ngIf="messageSuccess">{{messageSuccess}}</div>

          <div class="message-error" *ngIf="messageError">{{messageError}}</div>

          <ion-button color="success" type="submit" expand="block" [disabled]="!contactForm.valid" >{{text.submit}}</ion-button>

        </form>

      </ion-list>

      <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>
      <app-footer></app-footer>

      </ion-col>
    <ion-col class="ads" size="2"> </ion-col>
  </ion-row>
</ion-content>
