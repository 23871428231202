import { Component, Input } from '@angular/core';
import { TextService } from '../../services/text.service';
import { WebpageService } from '../../services/web.service';

@Component({
  selector: 'related-items',
  templateUrl: './related-items.component.html',
  styleUrls: ['./related-items.component.scss'],
  animations: [ ]
})
export class RelatedItemsComponent {

  @Input() relatedItems: any;
  text: any = {};
  locale$:any;
  locale: any;

  constructor(public textService: TextService, private webpageService: WebpageService) {

    this.locale$ = this.webpageService.getLocale().subscribe(async locale => {
      if (locale !== null) {
        this.locale = locale;
        this.translateTexts();
      }
    })

  }

  translateTexts() {
    this.text.subheading = this.textService.getTextLocale('subheading', 'relatedItems', this.locale);
    this.text.ariaLabel = this.textService.getTextLocale('ariaLabel', 'relatedItems', this.locale);
  }

  ngOnDestroy() { this.locale$.unsubscribe(); }



}
