
<ion-row class="header-space"></ion-row>
<ion-content force-overscroll="true">
  <ion-grid>
    <ion-row style="height: 100%">
      <ion-col class="ads" size="2"> </ion-col>
      <ion-col size-md="12" size-lg="8" class="main-column">

        <ion-list *ngIf="searchbarInputLength === 0" role="list">
          <ion-row>
            <ion-col role="listitem" *ngFor="let category of items" size-xs="12" size-sm="6" size-md="4" size-lg="4" size-xl="3">
              <a [attr.aria-label]="category.name + ' category'" class="item-container" [routerLink]="'/' + category?.path" [state]="{data: category}" role="button" tabindex="0">
                <div class="item-image-container">
                  <ion-skeleton-text [animated]="true" class="items" *ngIf="!category.loaded"></ion-skeleton-text>
                  <ion-img class="items" alt="{{category?.imageAlt}}" src="{{category?.img300}}" [ngClass]="{'fadeIn': category.loaded}" (ionImgDidLoad)="category.loaded = true"></ion-img>
                </div>
                <span  class="item-name">{{category?.name}}</span>
              </a>
            </ion-col>
          </ion-row>

        </ion-list>

        <ion-list *ngIf="searchbarInputLength > 0" class="ion-no-padding" role="list">

          <ion-row class="ion-no-padding">
            <ion-col class="ion-no-padding" role="listitem" *ngFor="let searchbarItem of searchbarItems | slice:0:12" size-xs="12" size-sm="6" size-md="4" size-lg="4">
              <div class="item-container" (click)="openSearchbarItem(searchbarItem)">
                <div class="item-image-container">
                  <ion-skeleton-text *ngIf="!searchbarItem.loaded" [animated]="true"></ion-skeleton-text>
                  <ion-img alt="{{searchbarItem.n}}" src="{{searchbarItem?.img300}}" [ngClass]="{'fadeIn': searchbarItem.loaded, 'article': searchbarItem.t === 'A'}" (ionImgDidLoad)="searchbarItem.loaded = true"></ion-img>
                </div>
                <span class="item-name">{{searchbarItem.n}}</span>
              </div>
            </ion-col>
          </ion-row>

        </ion-list>

      </ion-col>
      <ion-col class="ads" size="2"></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
