import { Component, Inject, Renderer2, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy} from '@angular/router';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { Platform } from '@ionic/angular';
import { WebpageService } from '../../services/web.service';
import { PreloadService } from '../../services/preload.service';
import { LocationService } from '../../services/location.service';
import { TextService } from '../../services/text.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-item',
  templateUrl: './item.page.html',
  styleUrls: ['./item.page.scss'],
})
export class ItemPage {

  searchbarResults$: Subscription | undefined;
  item: any;
  category: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  pageItems = 10;
  breadcrumbs: any[] = [];
  isBrowser: any;
  printJS: any;
  relatedItems: any;
  relatedCategories: any;
  webpage: any;
  locale: any;
  ads: any[] = [];
  text: any = {};
  currencySymbol$: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routeReuseStrategy: RouteReuseStrategy,
    @Inject(PLATFORM_ID) platformId: Object,
    private webpageService: WebpageService,
    private preloadService: PreloadService,
    private locationService: LocationService,
    private textService: TextService,
    public platform: Platform,
    public renderer: Renderer2) {

      this.routeReuseStrategy.shouldReuseRoute = () => false;

      this.isBrowser = isPlatformBrowser(platformId);

      this.route.url.subscribe(async segments => {
        this.locale = segments[0].path;
        this.translateTexts();
        this.webpage = await this.webpageService.getWebpageData(this.locale);
        this.webpageService.setLocale(this.locale);
        let itemSlug;
        if (this.router.getCurrentNavigation()?.extras.state?.['slug']) {
          itemSlug = this.router.getCurrentNavigation()?.extras.state?.['slug'];
        }
        if (!this.router.getCurrentNavigation()?.extras.state?.['slug']) {
          itemSlug = this.route.snapshot.paramMap.get('itemSlug');
        }

        await this.getItemData(itemSlug); // slug + locale
    });
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  async getItemData(itemSlug:any) {
    this.item = await this.webpageService.getItem(itemSlug, `${this.locale}-items`);
    this.item.img = `/assets/images/${this.item.image}`;
    this.preloadService.preloadImages([this.item.img]);
    this.category = await this.webpageService.getItem(this.item.categories[0], `${this.locale}-categories`);

    this.relatedItems = this.item.relatedItems;
    this.relatedItems.forEach((item:any) => {
      //console.log(item);
      item.img1x = this.webpageService.transformFilename(item.image); //, itemSlug, 'itemx');
      item.path = `${this.locale}/${this.webpage.itemSlug}/${item.slug}`;
    });
    this.relatedCategories = this.item.relatedCategories;
    this.relatedCategories.forEach((item:any) => {
       item.img1x = this.webpageService.transformFilename(item.image);
    });

    this.webpageService.setMetaTags(this.item);
    this.webpageService.initializeSearchbar(this.locale);
    this.webpageService.clearSearchbarInput();


    if (!this.isBrowser) {
      const data = await lastValueFrom(this.locationService.ads$);
      this.ads = this.webpageService.getRandomAds(data, 2);
    }
    if (this.isBrowser) {
      this.currencySymbol$ = this.locationService.getCurrencySymbol();
      this.locationService.ads$.subscribe(data => {
        this.ads = this.webpageService.getRandomAds(data, 2);
      });
    }

    if (!this.item) { this.router.navigateByUrl('/') }

  }

  async openSearchbarItem(item: any) { await this.webpageService.openSearchbarItem(item, this.locale); }

  print() {
    if (this.isBrowser) {
      import("print-js").then((data) => {
        this.printJS = data.default;
        const printable = `${this.webpage.cdn}/assets/images/${this.item.image}`;
        if (this.isBrowser) {
          this.printJS({
            printable,
            type: 'image',
            header: this.item.name,
            style: 'width:100%;position: absolute;top: 50%; transform: translateY(-50%)'
          })
        }
      });
    }
  }

  translateTexts() {
    this.text.adActionButton = this.textService.getTextLocale('adActionButton', 'item', this.locale);
  }
}
