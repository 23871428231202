<div *ngIf="showPopup" role="dialog" aria-live="polite" aria-label="cookieconsent" class="content-container">
    <span class="message">
        {{text.slogan}}
        <a [routerLink]="'/privacy-policy'" aria-label="learn more about cookies" role="button" tabindex="0"
          rel="noopener noreferrer nofollow">Learn more</a>
    </span>
    <div>
        <ion-button color="warning" (click)="dismiss()"  expand="block" fill="solid">
            OK
        </ion-button>
    </div>
</div>