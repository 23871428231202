import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePage } from '../pages/home/home.page';
import { AddItemPage } from '../pages/add-item/add-item.page';
import { ArticlePage } from '../pages/article/article.page';
import { ArticlesListPage } from '../pages/articles-list/articles-list.page';
import { CategoriesPage } from '../pages/categories/categories.page';
import { ContactPage } from 'src/pages/contact/contact.page';
import { ItemsListPage } from '../pages/items-list/items-list.page';
import { ItemPage } from '../pages/item/item.page';
import { PrivacyPolicyPage } from '../pages/privacy-policy/privacy-policy.page';

const routes: Routes = [
  // path: "locale/itemCategorySlug
  { path: 'de/tattoo-themen/:slug1', component: ItemsListPage },
  { path: 'de/tattoo-themen/:slug1/:slug2', component: ItemsListPage },
  { path: 'de/tattoo-themen/:slug1/:slug2/:slug3', component: ItemsListPage },
  { path: 'de/tattoo-themen/:slug1/:slug2/:slug3/:slug4', component: ItemsListPage },
  { path: 'en/tattoo-themes/:slug1', component: ItemsListPage },
  { path: 'en/tattoo-themes/:slug1/:slug2', component: ItemsListPage },
  { path: 'en/tattoo-themes/:slug1/:slug2/:slug3', component: ItemsListPage },
  { path: 'en/tattoo-themes/:slug1/:slug2/:slug3/:slug4', component: ItemsListPage },
  { path: 'es/temas-tatuajes/:slug1', component: ItemsListPage },
  { path: 'es/temas-tatuajes/:slug1/:slug2', component: ItemsListPage },
  { path: 'es/temas-tatuajes/:slug1/:slug2/:slug3', component: ItemsListPage },
  { path: 'es/temas-tatuajes/:slug1/:slug2/:slug3/:slug4', component: ItemsListPage },
  { path: 'fr/themes-tatouages/:slug1', component: ItemsListPage },
  { path: 'fr/themes-tatouages/:slug1/:slug2', component: ItemsListPage },
  { path: 'fr/themes-tatouages/:slug1/:slug2/:slug3', component: ItemsListPage },
  { path: 'fr/themes-tatouages/:slug1/:slug2/:slug3/:slug4', component: ItemsListPage },
  { path: 'it/temi-tatuaggi/:slug1', component: ItemsListPage },
  { path: 'it/temi-tatuaggi/:slug1/:slug2', component: ItemsListPage },
  { path: 'it/temi-tatuaggi/:slug1/:slug2/:slug3', component: ItemsListPage },
  { path: 'it/temi-tatuaggi/:slug1/:slug2/:slug3/:slug4', component: ItemsListPage },
  { path: 'pl/motywy-tatuaze/:slug1', component: ItemsListPage },
  { path: 'pl/motywy-tatuaze/:slug1/:slug2', component: ItemsListPage },
  { path: 'pl/motywy-tatuaze/:slug1/:slug2/:slug3', component: ItemsListPage },
  { path: 'pl/motywy-tatuaze/:slug1/:slug2/:slug3/:slug4', component: ItemsListPage },

  { path: 'de/tattoos/:itemSlug', component: ItemPage },
  { path: 'en/tattoos/:itemSlug', component: ItemPage },
  { path: 'es/tatuajes/:itemSlug', component: ItemPage },
  { path: 'fr/tatouages/:itemSlug', component: ItemPage },
  { path: 'it/tatuaggi/:itemSlug', component: ItemPage },
  { path: 'pl/tatuaze/:itemSlug', component: ItemPage },

  { path: 'de/artikel-tattoos', component: ItemsListPage },
  { path: 'de/artikel-tattoos/:itemSlug', component: ArticlesListPage },
  { path: 'en/articles', component: ItemsListPage },
  { path: 'en/articles/:itemSlug', component: ArticlesListPage },
  { path: 'es/articulos-tatuajes', component: ItemsListPage },
  { path: 'es/articulos-tatuajes/:itemSlug', component: ArticlesListPage },
  { path: 'fr/articles-tatouages', component: ItemsListPage },
  { path: 'fr/articles-tatouages/:itemSlug', component: ArticlesListPage },
  { path: 'it/articoli-tatuaggi', component: ItemsListPage },
  { path: 'it/articoli-tatuaggi/:itemSlug', component: ArticlesListPage },
  { path: 'pl/artykuly-tatuaze', component: ItemsListPage },
  { path: 'pl/artykuly-tatuaze/:itemSlug', component: ArticlesListPage },

  { path: 'de/tattoo-artikel/:itemSlug', component: ArticlePage },
  { path: 'en/tattoo-articles/:itemSlug', component: ArticlePage },
  { path: 'es/articulos-de-tatuajes/:itemSlug', component: ArticlePage },
  { path: 'fr/articles-de-tatouage/:itemSlug', component: ArticlePage },
  { path: 'it/articoli-di-tatuaggi/:itemSlug', component: ArticlePage },
  { path: 'pl/tatuaze-informacje/:itemSlug', component: ArticlePage },

  { path: 'de/tattoo-stile', component: ItemsListPage },
  { path: 'de/tattoo-stile/:itemSlug', component: ItemsListPage },
  { path: 'de/tattoos-korperteil', component: ItemsListPage },
  { path: 'de/tattoos-korperteil/:itemSlug', component: ItemsListPage },
  { path: 'en/tattoo-styles', component: ItemsListPage },
  { path: 'en/tattoo-styles/:itemSlug', component: ItemsListPage },
  { path: 'en/tattoo-body-parts', component: ItemsListPage },
  { path: 'en/tattoo-body-parts/:itemSlug', component: ItemsListPage },
  { path: 'es/tatuajes-estilos', component: ItemsListPage },
  { path: 'es/tatuajes-estilos/:itemSlug', component: ItemsListPage },
  { path: 'es/partes-del-cuerpo-tatuajes', component: ItemsListPage },
  { path: 'es/partes-del-cuerpo-tatuajes/:itemSlug', component: ItemsListPage },
  { path: 'fr/styles-de-tatouage', component: ItemsListPage },
  { path: 'fr/styles-de-tatouage/:itemSlug', component: ItemsListPage },
  { path: 'fr/parties-du-corps-tatouages', component: ItemsListPage },
  { path: 'fr/parties-du-corps-tatouages/:itemSlug', component: ItemsListPage },
  { path: 'it/tatuaggi-stili', component: ItemsListPage },
  { path: 'it/tatuaggi-stili/:itemSlug', component: ItemsListPage },
  { path: 'it/parti-del-corpo-tatuaggi', component: ItemsListPage },
  { path: 'it/parti-del-corpo-tatuaggi/:itemSlug', component: ItemsListPage },
  { path: 'pl/tatuaze-style', component: ItemsListPage },
  { path: 'pl/tatuaze-style/:itemSlug', component: ItemsListPage },
  { path: 'pl/czesci-ciala-tatuaze', component: ItemsListPage },
  { path: 'pl/czesci-ciala-tatuaze/:itemSlug', component: ItemsListPage },

  { path: 'de/tattoo-themen', component: CategoriesPage },
  { path: 'es/temas-tatuajes', component: CategoriesPage },
  { path: 'fr/themes-tatouages', component: CategoriesPage },
  { path: 'it/temi-tatuaggi', component: CategoriesPage },
  { path: 'pl/motywy-tatuaze', component: CategoriesPage },
  { path: 'en/tattoo-themes', component: CategoriesPage },

  { path: 'es/politica-de-privacidad', component: PrivacyPolicyPage },
  { path: 'fr/politique-de-confidentialite', component: PrivacyPolicyPage },
  { path: 'it/politica-sulla-privacy', component: PrivacyPolicyPage },
  { path: 'pl/polityka-prywatnosci', component: PrivacyPolicyPage },
  { path: 'de/datenschutzrichtlinie', component: PrivacyPolicyPage },
  { path: 'en/privacy-policy', component: PrivacyPolicyPage },

  { path: 'de/kontakt', component: ContactPage },
  { path: 'es/contacto', component: ContactPage },
  { path: 'fr/contact', component: ContactPage },
  { path: 'it/contatto', component: ContactPage },
  { path: 'pl/kontakt-z-nami', component: ContactPage },
  { path: 'en/contact-us', component: ContactPage },

  { path: 'de/tattoo-hinzufuegen', component: AddItemPage },
  { path: 'es/agregar-tatuaje', component: AddItemPage },
  { path: 'fr/ajouter-tatouage', component: AddItemPage },
  { path: 'it/aggiungi-tatuaggio', component: AddItemPage },
  { path: 'pl/dodaj-tatuaz', component: AddItemPage },
  { path: 'en/add-tattoo', component: AddItemPage },

  { path: 'de', component: HomePage },
  { path: 'es', component: HomePage },
  { path: 'fr', component: HomePage },
  { path: 'it', component: HomePage },
  { path: 'pl', component: HomePage },
  { path: '', component: HomePage },
  { path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
