import { Component, Input} from '@angular/core';

@Component({
  selector: 'ad-image',
  templateUrl: './ad-image.component.html',
  styleUrls: ['./ad-image.component.scss']
})

export class AdImageComponent {

  @Input() ad: any; // The ad object
  @Input() currencySymbol!: any; // The currency symbol
  @Input() adActionButton!: string; // The text for the action button

  constructor() {

    /*this.locale$ = this.webpageService.getLocale().subscribe(async locale => {
      if (locale !== null) {
        this.text.adActionButton = this.textService.getTextLocale('adActionButton', 'adImage', locale);
      }
    });*/
  }

}
