<ion-row class="header-space"></ion-row>
<ion-row>
  <ion-col class="ads" size="2"></ion-col>
  <ion-col size-md="12" size-lg="8" class="ion-no-padding">
    <div class="breadcrumbs-container">
      <span class="breadcrumbs" *ngFor="let breadcrumb of breadcrumbs; let last = last">
        <a rel="nofollow" [routerLink]="'/' + breadcrumb?.path" [state]="{data: breadcrumb, breadcrumbsNav: true}" >
          <small>{{ breadcrumb?.name }}</small>
          <small *ngIf="!last"> > </small>
        </a>
      </span>
    </div>
    <div class="h1-static-container">
      <h1 *ngIf="category?.name" class="item-name">{{category?.name}}</h1>
    </div>
  </ion-col>
  <ion-col class="ads" size="2"></ion-col>
</ion-row>

<ion-content>
  <ion-row *ngIf="category">
    <ion-col class="ads" size="2"></ion-col>
    <ion-col size-md="12" size-lg="8">

      <ion-list *ngIf="searchbarInputLength === 0" role="list">
        <ion-row>
          <ion-col role="listitem" *ngFor="let item of items | slice:0:itemsPerPage" size-xs="12" size-sm="6" size-md="4" size-lg="4" size-xl="3">
            <a class="item-container" [routerLink]="'/' + item.path" [state]="{data: item}" [attr.aria-label]="'Open ' + item?.name" role="button" tabindex="0" >
              <div class="item-image-container">
                <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}" ></ion-skeleton-text>
                <ion-img alt="{{item?.imageAlt}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
              </div>
              <span class="item-name">{{item.name}}</span>
            </a>
          </ion-col>
        </ion-row>

        <ion-infinite-scroll threshold="40%" (ionInfinite)="loadMoreData($event)">
          <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
        </ion-infinite-scroll>

        <div class="description">{{category?.description}}</div>
        <ion-img *ngIf="imageAppUrl" aria-hidden="true" alt="{{category?.imageAlt}}" class="test-app-image" src="{{imageAppUrl}}"></ion-img>

      </ion-list>

      <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>

      <app-footer></app-footer>
    </ion-col>
    <ion-col class="ads" size="2"></ion-col>
  </ion-row>
</ion-content>
